<script lang="ts">
    import { useNavigate } from 'svelte-navigator';
    import { onMount } from 'svelte';

    export let to: string;
    export let replace = false;

    const navigate = useNavigate();

    onMount(() => {
        navigate(to, { replace });
    });
</script>
