<script lang="ts">
    import type { PresentationJsonSlide } from '../../../logic/presentationJson';

    export let index: number;
    export let slide: PresentationJsonSlide;
</script>

<div class="column">
    {#if index % 2 === 0}
        <img class="image" src={slide.imagePath} alt="" />
    {:else}
        <h2 class="headline">{slide.headline}</h2>
    {/if}
</div>

<div class="column">
    {#if index % 2 === 0}
        <h2 class="headline">{slide.headline}</h2>
    {:else}
        <img class="image" src={slide.imagePath} alt="" />
    {/if}
</div>

<style>
    .headline {
        font-size: 5dvw;
        font-family: 'HeaderFont', sans-serif;
    }

    .image {
        width: 90%;
    }

    .column {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-basis: 40%;
        flex-grow: 1;
        padding: 5dvw;
        box-sizing: border-box;
    }
</style>
