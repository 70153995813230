<script lang="ts">
    import { onMount } from 'svelte';

    export let onVisible: () => void;

    let element: HTMLDivElement;

    onMount(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                onVisible();
            }
        }, {});

        observer.observe(element);
    });
</script>

<div bind:this={element} class="indicator" />

<style>
    .indicator {
        height: 1px;
        width: 1px;
    }
</style>
