import { firstValueFrom } from 'rxjs';
import { loginStatus$ } from '../store/loginV2';
import { suggestions } from '../store/suggestions';
import { LoginStatus } from '../../common/types';
import { api } from '../../common/api';

const getSuggestion = api.path('/api/suggestion').method('get').create();

export async function getSuggestions() {
    const loginStatus = await firstValueFrom(loginStatus$);
    if (loginStatus.status !== LoginStatus.LOGGED_IN) {
        throw new Error('need to be logged in to load suggestions');
    }
    const { data } = await getSuggestion(
        {},
        { headers: { authorization: `Bearer ${loginStatus.token}` } }
    );

    suggestions.set(data);
}
