<script lang="ts">
    import { onMount } from 'svelte';
    import { useNavigate } from 'svelte-navigator';
    import { publishPresentation } from '../actions/publishPresentation';

    export let presentationId: string;

    const navigate = useNavigate();
    let errorMessage: string | undefined = undefined;

    onMount(() => {
        if (presentationId) {
            publishPresentation(presentationId).then(
                () => {
                    navigate('/');
                },
                (error) => {
                    errorMessage =
                        error.message ?? 'an unexpected error occurred';
                }
            );
        } else {
            navigate('/');
        }
    });
</script>

<h2>Publishing your presentation, please wait...</h2>
