import { firstValueFrom } from 'rxjs';
import { loginStatus$ } from '../store/loginV2';
import { getSuggestions } from './getSuggestions';
import { LoginStatus } from '../../common/types';
import { api } from '../../common/api';

const doDeleteSuggestion = api
    .path('/api/suggestion/{suggestionId}')
    .method('delete')
    .create();

export async function deleteSuggestion(suggestionId: string) {
    const loginStatus = await firstValueFrom(loginStatus$);
    if (loginStatus.status !== LoginStatus.LOGGED_IN) {
        throw new Error('need to be logged in to load suggestions');
    }
    await doDeleteSuggestion(
        { suggestionId },
        { headers: { authorization: `Bearer ${loginStatus.token}` } }
    );
    await getSuggestions();
}
