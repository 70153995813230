<script lang="ts">
    import { PresentationByKind } from './kinds';
    import AudioVideoPlayer from './AudioVideoPlayer.svelte';
    import RotatePrompt from './RotatePrompt.svelte';
    import KeyboardControls from './KeyboardControls.svelte';
    import {
        getPresentationLogic,
        isLoaded,
        isLoading,
        isLoadingError,
    } from './logic/pagerLogic';
    import Preloader from './Preloader.svelte';

    export let presentationIndexURL: string = '';
    export let hideRotatePrompt = false;
    export let showTngLogo = true;

    const {
        slideState,
        nextSlide,
        previousSlide,
        play,
        restart,
        pause,
        toggleSubtitles,
        playQuestionAnswer,
    } = getPresentationLogic(presentationIndexURL);

    export {
        slideState,
        nextSlide,
        previousSlide,
        play,
        restart,
        pause,
        toggleSubtitles,
        playQuestionAnswer,
    };
</script>

{#if true}
    {@const state = $slideState}
    {#if isLoading(state)}
        <slot name="loading">Loading...</slot>
    {:else if isLoadingError(state)}
        <slot name="error" error={state.error}>
            {state.error?.message}
        </slot>
    {:else}
        <Preloader presentation={state.presentation} />
        <div class="presentation-panel" inert>
            <slot name="display" slideState={state}>
                <svelte:component
                    this={PresentationByKind[state.presentation.kind]}
                    slide={state}
                    {showTngLogo}
                />
            </slot>
        </div>
        <div class="control-panel">
            <slot
                name="controls"
                {nextSlide}
                {previousSlide}
                {pause}
                {play}
                {restart}
                {toggleSubtitles}
                {playQuestionAnswer}
                slideState={state}
            >
                <AudioVideoPlayer
                    {nextSlide}
                    {play}
                    slideState={state}
                    autoPlay={true}
                />
                <KeyboardControls
                    {nextSlide}
                    {previousSlide}
                    {pause}
                    {play}
                    {restart}
                    {toggleSubtitles}
                    slideState={state}
                />
            </slot>
        </div>
        {#if !hideRotatePrompt}
            <RotatePrompt />
        {/if}
    {/if}
{/if}

<style>
    .presentation-panel {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .control-panel {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
