<script lang="ts">
    import { requestLogout } from '../../store/loginV2';
    import { _ } from 'svelte-i18n';

    function logout(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        requestLogout.next();
    }
</script>

<button on:click={logout} class="button"
    >{$_('landingpage.login.logout')}</button
>

<style>
    .button {
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 0.1rem solid var(--primary-foreground);
        border-radius: 0.25rem;
        color: var(--primary-foreground);
        background-color: var(--primary-background);
        cursor: pointer;
    }

    .button:hover {
        border-color: #eee;
        color: var(--active-foreground);
        box-shadow: 0 0 1rem rgba(255, 255, 255, 0.2);
    }
</style>
