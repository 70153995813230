import type { PresentationJson } from './presentationJson';
import { loadPresentationJson } from './presentationJson';
import {
    type FirstSlidePaused,
    type SlideDisplayState,
    SlideDisplayTag,
} from './slideDisplay';
import { readonly, writable } from 'svelte/store';

export type LoadingPresentation = {
    tag: 'LOADING_PRESENTATION';
};

export type LoadingPresentationError = {
    tag: 'LOADING_PRESENTATION_ERROR';
    error: unknown;
};

export type PresentationLogicState =
    | LoadingPresentation
    | LoadingPresentationError
    | SlideDisplayState;

export function isLoaded(
    state: PresentationLogicState
): state is SlideDisplayState {
    return !isLoading(state) && !isLoadingError(state);
}

export function isLoading(
    state: PresentationLogicState
): state is LoadingPresentation {
    return state.tag === 'LOADING_PRESENTATION';
}

export function isLoadingError(
    state: PresentationLogicState
): state is LoadingPresentationError {
    return state.tag === 'LOADING_PRESENTATION_ERROR';
}

export function getAreSubtitlesAvailable(state: PresentationLogicState) {
    return (
        isLoaded(state) &&
        state.presentation.slides[0]?.transcription !== undefined
    );
}

export function getAreSubtitlesVisible(state: PresentationLogicState) {
    return isLoaded(state) && state.displayState.subtitlesVisible;
}

export function getIsQuestionAnswerAvailable(state: PresentationLogicState) {
    return (
        isLoaded(state) &&
        !!state.presentation.settings &&
        !!state.presentation.settings.person
    );
}

export function getPresentationLogic(presentationURL: string) {
    function getSlides(state: SlideDisplayState) {
        return [
            state.presentation.initialSlide,
            ...state.presentation.slides,
            state.presentation.finalSlide,
        ];
    }

    function getInitialState(state: PresentationJson): FirstSlidePaused {
        return {
            tag: SlideDisplayTag.FIRST_SLIDE_PAUSED,
            displayState: {
                subtitlesVisible: true,
            },
            currentSlide: state.initialSlide,
            presentation: state,
            slideIndex: 0,
        };
    }

    const slideState = writable<PresentationLogicState>({
        tag: 'LOADING_PRESENTATION',
    });

    void loadPresentationJson(presentationURL).then(
        (presentationJson) => {
            slideState.set(getInitialState(presentationJson));
        },
        (error) => {
            slideState.set({
                tag: 'LOADING_PRESENTATION_ERROR',
                error,
            });
        }
    );

    function nextSlide() {
        slideState.update((state): PresentationLogicState => {
            if (!isLoaded(state)) {
                return state;
            }
            const allSlides = getSlides(state);
            const nextSlide = Math.min(
                state.slideIndex + 1,
                allSlides.length - 1
            );
            switch (state.tag) {
                case SlideDisplayTag.LAST_SLIDE_PAUSED:
                case SlideDisplayTag.LAST_SLIDE_FINISHED:
                case SlideDisplayTag.LAST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_FINISHED,
                    };
                case SlideDisplayTag.FIRST_SLIDE_PAUSED:
                case SlideDisplayTag.PAUSED:
                    return {
                        ...state,
                        tag:
                            nextSlide >= allSlides.length - 1
                                ? SlideDisplayTag.LAST_SLIDE_PAUSED
                                : SlideDisplayTag.PAUSED,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                case SlideDisplayTag.FIRST_SLIDE_PLAYING:
                case SlideDisplayTag.PLAYING:
                    return {
                        ...state,
                        tag:
                            nextSlide >= allSlides.length - 1
                                ? SlideDisplayTag.LAST_SLIDE_PLAYING
                                : SlideDisplayTag.PLAYING,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                case SlideDisplayTag.Q_AND_A:
                    return {
                        presentation: state.presentation,
                        currentSlide: state.currentSlide,
                        slideIndex: state.slideIndex,
                        tag: SlideDisplayTag.LAST_SLIDE_FINISHED,
                        displayState: state.displayState,
                    };
                default:
                    return state;
            }
        });
    }

    function previousSlide() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            const allSlides = getSlides(state);
            const nextSlide = Math.max(state.slideIndex - 1, 0);
            switch (state.tag) {
                case SlideDisplayTag.FIRST_SLIDE_PAUSED:
                case SlideDisplayTag.FIRST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.FIRST_SLIDE_PAUSED,
                    };
                case SlideDisplayTag.LAST_SLIDE_PAUSED:
                case SlideDisplayTag.PAUSED:
                    return {
                        ...state,
                        tag:
                            nextSlide <= 0
                                ? SlideDisplayTag.FIRST_SLIDE_PAUSED
                                : SlideDisplayTag.PAUSED,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                case SlideDisplayTag.LAST_SLIDE_FINISHED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_PAUSED,
                    };
                case SlideDisplayTag.LAST_SLIDE_PLAYING:
                case SlideDisplayTag.PLAYING:
                    return {
                        ...state,
                        tag:
                            nextSlide <= 0
                                ? SlideDisplayTag.FIRST_SLIDE_PLAYING
                                : SlideDisplayTag.PLAYING,
                        currentSlide: allSlides[nextSlide],
                        slideIndex: nextSlide,
                    };
                default:
                    return state;
            }
        });
    }

    function pause() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            switch (state.tag) {
                case SlideDisplayTag.FIRST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.FIRST_SLIDE_PAUSED,
                    };
                case SlideDisplayTag.PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.PAUSED,
                    };
                case SlideDisplayTag.LAST_SLIDE_PLAYING:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_PAUSED,
                    };
                default:
                    return state;
            }
        });
    }

    function play() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            switch (state.tag) {
                case SlideDisplayTag.FIRST_SLIDE_PAUSED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.FIRST_SLIDE_PLAYING,
                    };
                case SlideDisplayTag.PAUSED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.PLAYING,
                    };
                case SlideDisplayTag.LAST_SLIDE_PAUSED:
                case SlideDisplayTag.LAST_SLIDE_FINISHED:
                    return {
                        ...state,
                        tag: SlideDisplayTag.LAST_SLIDE_PLAYING,
                    };
                default:
                    return state;
            }
        });
    }

    function restart() {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }
            return getInitialState(state.presentation);
        });
    }

    function playQuestionAnswer(
        audioPath: string,
        videoPath: string | undefined
    ) {
        slideState.update((state) => {
            if (!isLoaded(state)) {
                return state;
            }

            return {
                ...state,
                tag: SlideDisplayTag.Q_AND_A,
                answer: {
                    audioPath: audioPath,
                    videoPath: videoPath,
                },
            };
        });
    }

    function toggleSubtitles() {
        slideState.update((state) => {
            if (
                state.tag !== 'LOADING_PRESENTATION' &&
                state.tag !== 'LOADING_PRESENTATION_ERROR'
            ) {
                state.displayState.subtitlesVisible =
                    !state.displayState.subtitlesVisible;
            }
            return state;
        });
    }

    return {
        slideState: readonly(slideState),
        nextSlide,
        previousSlide,
        pause,
        play,
        restart,
        toggleSubtitles,
        playQuestionAnswer,
    };
}
