<script lang="ts">
    import { Route } from 'svelte-navigator';
    import PrivateRoute from '../../lib/guard/PrivateRoute.svelte';
    import Read from '../common/Read.svelte';
    import New from '../common/New.svelte';
    import backgroundUrl from '../../../common/assets/dark-tales-background.png';
    import lady from '../../../common/assets/lady.png';
    import Redirect from '../../../common/Redirect.svelte';
    import { SimplePresentationKind } from '../../../common/types';
    import { _ } from 'svelte-i18n';

    const thinkingSymbols = '☠⚰⚱✞✝࿅';
</script>

<div
    class="page-wrapper dark-tales"
    style={`background-image:url('${backgroundUrl}');'`}
>
    <h1 class="heading">{$_('story.darkTalesTitle')}</h1>
    <PrivateRoute path="new">
        <New
            creatorImage={lady}
            storyKind={SimplePresentationKind.DARK_TALE}
            thinkingCharCodes={thinkingSymbols}
        />
    </PrivateRoute>
    <Route path="read/:id" let:params>
        <Read id={params.id} baseStoryUrlPath="dark-tales" />
    </Route>
    <PrivateRoute path="*">
        <Redirect to={`/dark-tales/new`} replace={true} />
    </PrivateRoute>
</div>

<style>
    @font-face {
        font-family: MainHeaderFont;
        src: url('../../../common/assets/abandon.ttf');
    }

    @font-face {
        font-family: TextFont;
        src: url('../../../common/assets/seagram.ttf');
    }

    :global(.dark-tales .text) {
        font-family: TextFont, sans-serif;
    }

    :global(.dark-tales .heading) {
        font-family: MainHeaderFont, sans-serif;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100svh;
        width: 100svw;
        background-size: 100% 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

    .heading {
        margin: 4rem;
        font-size: 4vw;
        color: #c71111;
    }
</style>
