<script lang="ts">
    import { onMount } from 'svelte';
    import { derived } from 'svelte/store';
    import { tap } from 'rxjs';
    import { newPresentationOptions } from '../../store/presentationOptions';
    import { generateTopic } from '../../actions/generateTopic';
    import { createNewPerson } from '../../actions/newPresentation';
    import { getSuggestions } from '../../actions/getSuggestions';
    import type { NewPresentationSettings } from '../../../common/types';
    import { suggestions } from '../../store/suggestions';
    import { _, json } from 'svelte-i18n';
    import RecordUserData from '../../../common/createPresentation/RecordUserData.svelte';
    import UploadUserData from '../UploadUserData.svelte';

    export let generatePresentation: (
        settings: NewPresentationSettings
    ) => void;

    const defaultPersonality = 'honestPresenter';
    const defaultSpeaker = 'Justin';
    const youSpeaker = 'You';

    const speakerOptions = derived(
        newPresentationOptions,
        (options) => options.speakers
    );
    const personalityOptions = derived(newPresentationOptions, (options) =>
        options.personalities.filter((p) => p.showInFrontend)
    );
    const imageGeneratorOptions = derived(
        newPresentationOptions,
        (options) => options.imageGenerators
    );
    const speakerVideoGeneratorOptions = derived(
        newPresentationOptions,
        (options) => options.speakerVideoGenerators
    );
    const textGeneratorOptions = derived(
        newPresentationOptions,
        (options) => options.textGenerators
    );
    const slideOptions = derived(
        newPresentationOptions,
        (options) => options.slides
    );

    let recordUser: RecordUserData;
    //let personSelected: boolean = false;

    let selectedSuggestion: string;
    let suggestionModal: HTMLDialogElement;

    let topic: string;
    let language: string;
    let numberOfSlides: string;
    let speaker: string;
    let imageGenerator: string;
    let speakerVideoGenerator: string;
    let textGenerator: string;
    let personality: string = 'no-personality';

    let personName: string;
    let personDescription: string;
    let narrationStyle: string;
    let personImageDescription: string;
    let personImage: string;
    let personAudio: string;

    let createCoverSlideVideo: boolean = false;
    let createSubtitles: boolean = true;
    let contentModeration: boolean = false;

    let promotion: string = 'none';

    let showPersonData: boolean = false;

    $: submitDisabled =
        !topic ||
        topic.length < 3 ||
        !personDescription ||
        !personImageDescription ||
        !personName ||
        !speaker ||
        (personality === 'you' && (!personImage || !personAudio));

    async function generateNewTopic(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        console.log('Generating topic');
        topic = await generateTopic();
        console.log(`Generated topic "${topic}"`);
    }

    enum GeneratorStatus {
        WAITING,
        GENERATING,
        ERROR,
        ABORT,
    }

    let generatePersonStatus: GeneratorStatus = GeneratorStatus.WAITING;

    function generatePerson(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        generatePersonStatus = GeneratorStatus.GENERATING;

        createNewPerson({
            name: personName,
            personDescription: personDescription,
            imageDescription: personImageDescription,
            speaker: speaker,
            textGeneratorName: textGenerator,
        })
            .pipe(
                tap((msg) => {
                    console.log(msg);
                })
            )
            .subscribe({
                next(message) {
                    if (message.type === 'error') {
                        generatePersonStatus = GeneratorStatus.ERROR;
                    }
                    if (message.type === 'abort') {
                        generatePersonStatus = GeneratorStatus.ABORT;
                    }
                    if (message.type == 'created-person') {
                        generatePersonStatus = GeneratorStatus.WAITING;
                        personName = message.payload.name;
                        personDescription = message.payload.personDescription;
                        speaker = message.payload.speaker;
                        personImageDescription =
                            message.payload.imageDescription;
                    }
                },
                error(err) {
                    console.error(err);
                    generatePersonStatus = GeneratorStatus.ERROR;
                },
                complete() {},
            });

        return generatePersonStatus;
    }

    async function selectSuggestion(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (suggestionModal) {
            await getSuggestions();
            suggestionModal.showModal();
        }
    }

    function useSelection(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        topic = selectedSuggestion;

        if (suggestionModal) {
            suggestionModal.close();
        }
    }

    function personalityChanged(event: any) {
        personality = event.target.value;
        selectPersonality(personality);
    }

    function selectPersonality(personality: string) {
        if (personality === 'custom') {
            personName = '';
            personDescription = '';
            personImageDescription = '';
            speaker = defaultSpeaker;
        } else if (personality === 'you') {
            personName = '';
            personDescription = '';
            personImageDescription = 'An image of the user themself';
            speaker = youSpeaker;
        } else {
            const person = $personalityOptions.find(
                (entry) => entry.value === personality
            );
            personName = person?.name || '';
            personDescription = person?.personDescription || '';
            personImageDescription = person?.imageDescription || '';
            speaker = person?.speaker || 'Joanna';
        }
    }

    function clearPerson(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        personName = '';
        personDescription = '';
        personImageDescription = '';
    }

    function newPersonRecording(
        event: CustomEvent<{ encodedImage: string; encodedAudio: string }>
    ) {
        personImage = event.detail.encodedImage;
        personAudio = event.detail.encodedAudio;
    }

    function changePersonDataVisibility(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        showPersonData = !showPersonData;
    }

    function submit(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        language =
            $speakerOptions.find((entry) => entry.value === speaker)
                ?.language || getLanguageFromLocale();

        const settings = {
            title: topic,
            numberOfSlides: Number.parseInt(numberOfSlides),
            narrationStyle: narrationStyle,
            textGeneratorName: textGenerator,
            imageGeneratorName: imageGenerator,
            speakerVideoGeneratorName: speakerVideoGenerator,
            language: language,
            createCoverSlideVideo: createCoverSlideVideo,
            createSubtitles: createSubtitles,
            contentModeration: contentModeration,
            promotion: promotion,
            person: {
                name: personName,
                personDescription: personDescription,
                imageDescription: personImageDescription,
                speaker: speaker,
                image: personImage,
                audio: personAudio,
            },
        };

        generatePresentation(settings);
    }

    function getLanguageFromLocale() {
        return $json('languageNameForGeneration') as string;
    }

    function handlePromotion(event: Event) {
        const target = event.target as HTMLInputElement | undefined;
        if (target && target.value) {
            promotion = target?.value;
        }
    }

    async function startCapture(event: Event) {
        event.preventDefault();
        await recordUser.startCapture();
        return false;
    }

    onMount(() => {
        personality = defaultPersonality;
        selectPersonality(personality);
    });
</script>

<div class="left">
    <div class="left-child" style="width: 600px;">
        <dialog bind:this={suggestionModal}>
            <form on:submit={useSelection}>
                <select bind:value={selectedSuggestion}>
                    {#each $suggestions as suggestion}
                        <option value={suggestion.suggestion}
                            >{suggestion.suggestion}</option
                        >
                    {/each}
                </select>
                <input type="submit" value="use" />
            </form>
        </dialog>

        <RecordUserData
            bind:this={recordUser}
            on:newRecording={newPersonRecording}
        />

        <form class="presentation-form">
            <label for="topic">{$_('landingpage.newPresentation.topic')}</label>
            <input type="text" id="topic" bind:value={topic} />
            <div />
            <div class="two-buttons">
                <button class="split" on:click={generateNewTopic}
                    >{$_('landingpage.newPresentation.generateTopic')}</button
                >
                <button class="split" on:click={selectSuggestion}
                    >{$_('landingpage.newPresentation.useSuggestion')}</button
                >
            </div>

            <label for="slide"
                >{$_('landingpage.newPresentation.numberOfSlides')}</label
            >
            <select id="slide" bind:value={numberOfSlides}>
                {#each $slideOptions as slideOption}
                    <option value={slideOption.value}
                        >{slideOption.label}</option
                    >
                {/each}
            </select>

            <label for="narration-style"
                >{$_('landingpage.newPresentation.narrationStyle')}</label
            >
            <textarea
                class="text-field"
                id="narration-style"
                bind:value={narrationStyle}
            />

            <label for="personality"
                >{$_('landingpage.newPresentation.personality')}</label
            >
            <select
                id="personality"
                bind:value={personality}
                on:input={personalityChanged}
            >
                {#each $personalityOptions as personalityOption}
                    <option value={personalityOption.value}
                        >{personalityOption.label}</option
                    >
                {/each}
                <option value="custom"
                    >{$_(
                        'landingpage.newPresentation.customPersonality'
                    )}</option
                >
                <option value="you"
                    >{$_('landingpage.newPresentation.yourPersonality')}</option
                >
            </select>

            <div />

            <button class="split" on:click={changePersonDataVisibility}
                >{$_(
                    showPersonData
                        ? 'landingpage.newPresentation.hidePersonData'
                        : 'landingpage.newPresentation.showPersonData'
                )}</button
            >

            {#if showPersonData}
                <hr />

                <label for="person-name"
                    >{$_('landingpage.newPresentation.person.name')}</label
                >
                <input type="text" id="person-name" bind:value={personName} />

                <label for="person-description"
                    >{$_(
                        'landingpage.newPresentation.person.description'
                    )}</label
                >
                <textarea
                    class="text-field"
                    id="person-description"
                    bind:value={personDescription}
                />

                {#if personality !== 'you'}
                    <label for="speaker"
                        >{$_(
                            'landingpage.newPresentation.person.speaker'
                        )}</label
                    >

                    <select
                        id="speaker"
                        bind:value={speaker}
                        class="speaker-dropdown"
                    >
                        {#each $speakerOptions as speakerOption}
                            <option value={speakerOption.value}
                                >{speakerOption.label}</option
                            >
                        {/each}
                    </select>

                    <label for="profile-picture-description"
                        >{$_(
                            'landingpage.newPresentation.person.imageDescription'
                        )}</label
                    >
                    <textarea
                        class="text-field"
                        id="profile-picture-description"
                        bind:value={personImageDescription}
                    />
                {:else}
                    <label for="person-description">
                        {$_('landingpage.newPresentation.person.recording')}
                    </label>
                    <div>
                        <UploadUserData
                            bind:encodedImage={personImage}
                            bind:encodedAudio={personAudio}
                        />

                        <button class="full-size" on:click={startCapture}>
                            {$_('record.startRecording')}
                        </button>
                        <p class="red">
                            {#if !personImage || !personAudio}
                                {$_(
                                    'landingpage.newPresentation.person.noRecording'
                                )}
                            {/if}
                        </p>
                    </div>
                {/if}

                <div />
                <div class="two-buttons">
                    <button class="split" on:click={generatePerson}>
                        {$_('landingpage.newPresentation.person.fillBlanks')}
                    </button>
                    <button class="split" on:click={clearPerson}>
                        {$_('landingpage.newPresentation.person.clear')}
                    </button>
                </div>

                <hr />
            {/if}

            <label for="imageGenerator"
                >{$_('landingpage.newPresentation.imageGenerator')}</label
            >
            <select id="imageGenerator" bind:value={imageGenerator}>
                {#each $imageGeneratorOptions as imageGeneratorOption}
                    <option value={imageGeneratorOption.value}
                        >{imageGeneratorOption.label}</option
                    >
                {/each}
            </select>

            <label for="speakerVideoGenerator"
                >{$_('landingpage.newPresentation.videoGenerator')}</label
            >
            <select
                id="speakerVideoGenerator"
                bind:value={speakerVideoGenerator}
            >
                {#each $speakerVideoGeneratorOptions as speakerVideoGeneratorOption}
                    <option value={speakerVideoGeneratorOption.value}
                        >{speakerVideoGeneratorOption.label}</option
                    >
                {/each}
            </select>

            <label for="generator"
                >{$_('landingpage.newPresentation.textGenerator')}</label
            >
            <select id="textGenerator" bind:value={textGenerator}>
                {#each $textGeneratorOptions as textGeneratorOption}
                    <option value={textGeneratorOption.value}
                        >{textGeneratorOption.label}</option
                    >
                {/each}
            </select>

            <label for="initial-video"
                >{$_('landingpage.newPresentation.coverSlideVideo')}</label
            >
            <div>
                <input
                    type="checkbox"
                    id="initial-video"
                    bind:checked={createCoverSlideVideo}
                />
                {$_('landingpage.newPresentation.createCoverSlideVideo')}
            </div>

            <label for="subtitles"
                >{$_('landingpage.newPresentation.subtitles')}</label
            >
            <div>
                <input
                    type="checkbox"
                    id="subtitles"
                    bind:checked={createSubtitles}
                />
                {$_('landingpage.newPresentation.createSubtitles')}
            </div>

            <label for="content-moderation"
                >{$_('landingpage.newPresentation.content')}</label
            >
            <div>
                <input
                    type="checkbox"
                    id="content-moderation"
                    bind:checked={contentModeration}
                />
                {$_('landingpage.newPresentation.moderateContent')}
            </div>

            <label for="content-moderation"
                >{$_('landingpage.newPresentation.promotion')}</label
            >
            <div>
                <label>
                    <input
                        type="radio"
                        bind:group={promotion}
                        value="none"
                        on:change={handlePromotion}
                    />
                    {$_('landingpage.newPresentation.noPromotion')}
                </label>
                <label>
                    <input
                        type="radio"
                        bind:group={promotion}
                        value="xlpr"
                        on:change={handlePromotion}
                    />
                    XLPR
                </label>
            </div>

            <input
                type="submit"
                value={$_('landingpage.newPresentation.createPresentation')}
                class="submit-button"
                disabled={submitDisabled}
                on:click={submit}
            />
        </form>
    </div>
</div>

<style>
    hr {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .left {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .speaker-dropdown {
        height: 30px;
    }

    .text-field {
        height: 100px;
        resize: none;
    }

    .two-buttons {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: baseline;
    }

    button.split {
        flex-grow: 1;
        margin-left: 0.5%;
    }

    .submit-button {
        grid-column-start: 1;
        grid-column-end: 3;

        margin-top: 30px;
        margin-bottom: 30px;
    }

    .presentation-form {
        display: grid;
        grid-template-columns: 30% 70%;
        gap: 0.5rem;
    }

    .presentation-form label {
        text-align: left;
    }

    @media (max-width: 400px) {
        .presentation-form {
            grid-template-columns: 1fr;
        }

        .presentation-form label {
            text-align: left;
        }
    }

    .full-size {
        width: 100%;
    }

    .red {
        color: red;
    }
</style>
