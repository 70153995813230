<script lang="ts">
    import {
        type AuthenticatorState,
        getPresentationCreator,
    } from './logic/stateMachine';
    import type { Observable } from 'rxjs';

    export let authenticator: Observable<AuthenticatorState>;
    export let timeout: number = 1000 * 60 * 10;

    const state = getPresentationCreator(authenticator, timeout);
</script>

{#if state}
    {@const creationState = $state}
    <slot state={creationState} />
{/if}
