import { z } from 'zod';
import avatarBMW from '../assets/avatar-bmw.webp';
import avatarMuseum from '../assets/avatar-museum.webp';
import avatarTNG from '../assets/avatar-tng.webp';
import futureLabLogo from '../assets/future_lab_logo.svg';
import bmwLogo from '../assets/bmw.webp';
import deutschesMuseumLogo from '../assets/deutsches_museum_logo.svg';
import languageSwitcherDefault from '../../common/assets/i18n.svg';
import languageSwitcherFL from '../assets/language_icon_future_lab.svg';

const mode = z
    .enum(['presentation', 'story', 'darktale', 'xmas'])
    .catch('presentation');

export type Theme = {
    previewPresentations: Array<string>;
    logo?: string;
    logoCompact?: string;
    avatar: string;
    showTngLogo: boolean;
    languageSwitcher: string;
    overview: {
        presentationLines: [number, number];
    };
    infoFooter: boolean;
    showImprint: boolean;
    privacyInfo: {
        privacyUrl: string;
        location?: string;
    };
    useReadingTextVariant?: boolean;
};

const theme = z
    .enum(['future-lab', 'deutsches-museum', 'tng'])
    .catch('tng')
    .transform((themeId): Theme => {
        switch (themeId) {
            case 'future-lab':
                void import('../themeFutureLab.css');
                return {
                    logo: futureLabLogo,
                    logoCompact: bmwLogo,
                    avatar: avatarBMW,
                    languageSwitcher: languageSwitcherFL,
                    showTngLogo: false,
                    previewPresentations: [
                        '1715762501888', // The Future is Here (Patrick)
                        '1686766510321', // Lernen ist nutzlos, frag doch einfach ChatGPT
                        '1686765648326', // Künstliche Intelligenz in Science Fiction am Beispiel von Mr. Data
                        '1686767094843', // Warum Hühner das passive Wahlrecht haben sollten
                    ],
                    overview: {
                        presentationLines: [4, 0],
                    },
                    infoFooter: true,
                    showImprint: true,
                    privacyInfo: {
                        privacyUrl:
                            'https://futurelab.bmwgroup.net/privacy-policy/',
                        location: 'newPresentation.dataPrivacy.futureLab',
                    },
                };
            case 'deutsches-museum':
                return {
                    logo: deutschesMuseumLogo,
                    avatar: avatarMuseum,
                    languageSwitcher: languageSwitcherDefault,
                    showTngLogo: true,
                    previewPresentations: [
                        '1686765213768', // Verwendung von KI in Business-Präsentationen
                        '1686766510321', // Lernen ist nutzlos, frag doch einfach ChatGPT
                        '1686765648326', // Künstliche Intelligenz in Science Fiction am Beispiel von Mr. Data
                        '1686767094843', // Warum Hühner das passive Wahlrecht haben sollten
                        '1686766162517', // Warum sich ein Besuch im Deutschen Museum lohnt
                        '1686767546279', // Die top drei der schlechtesten Filme aller Zeiten
                    ],
                    overview: {
                        presentationLines: [3, 3],
                    },
                    infoFooter: false,
                    showImprint: false,
                    privacyInfo: {
                        privacyUrl:
                            'https://www.deutsches-museum.de/datenschutz',
                    },
                    useReadingTextVariant: true,
                };
            case 'tng':
                return {
                    avatar: avatarTNG,
                    languageSwitcher: languageSwitcherDefault,
                    showTngLogo: true,
                    previewPresentations: [
                        'tng-01', // The Big Techday
                        'tng-02', // Who is TNG
                        'tng-03', // How AI is streamlining business processes
                        'tng-04', // Novel uses for Milk
                        'tng-05', // All Cats Should Be Turned into Dogfood
                        'tng-06', // Why parallel execution is the bee's knees
                    ],
                    overview: {
                        presentationLines: [3, 3],
                    },
                    infoFooter: false,
                    showImprint: false,
                    privacyInfo: {
                        privacyUrl: 'https://ai-slides.com/#/privacy',
                    },
                };
        }
    });

export type Settings = {
    blurBackground: boolean;
    onScreenKeyboard: boolean;
    prefillUsername: string;
    autoplayPresentations: boolean;
    mode: z.output<typeof mode>;
    theme: Theme;
    splitScreen: boolean;
    enableQnA: boolean;
} & (
    | { splitScreen: true; remoteScreen: false }
    | { splitScreen: false; remoteScreen: true }
    | { splitScreen: false; remoteScreen: false }
);

const url = new URL(document.location.href);

function getSplitScreenConfig() {
    switch (url.searchParams.get(QUERY_PARAMS.SPLIT_SCREEN)) {
        case null:
            return {
                remoteScreen: false as const,
                splitScreen: false as const,
            };
        case 'remote':
            return { remoteScreen: true as const, splitScreen: false as const };
        default:
            return { remoteScreen: false as const, splitScreen: true as const };
    }
}

export enum QUERY_PARAMS {
    ONSCREEN_KEYBOARD = 'osk',
    PREFILLED_USERNAME = 'user',
    AUTOPLAY_PRESENTATIONS = 'autoplay',
    PRESENTATION_KIND = 'mode',
    UI_THEME = 'theme',
    QUESTION_AND_ANSWER = 'qna',
    SPLIT_SCREEN = 'split',
    BLUR_CAMERA_BACKGROUND = 'blur',
}

export const settings: Settings = {
    blurBackground:
        url.searchParams.get(QUERY_PARAMS.BLUR_CAMERA_BACKGROUND) !== null,
    onScreenKeyboard:
        url.searchParams.get(QUERY_PARAMS.ONSCREEN_KEYBOARD) !== null,
    prefillUsername:
        url.searchParams.get(QUERY_PARAMS.PREFILLED_USERNAME) ?? '',
    autoplayPresentations:
        url.searchParams.get(QUERY_PARAMS.AUTOPLAY_PRESENTATIONS) !== null,
    mode: mode.parse(url.searchParams.get(QUERY_PARAMS.PRESENTATION_KIND)),
    theme: theme.parse(url.searchParams.get(QUERY_PARAMS.UI_THEME)),
    enableQnA: url.searchParams.get(QUERY_PARAMS.QUESTION_AND_ANSWER) !== null,
    ...getSplitScreenConfig(),
};
