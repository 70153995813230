<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import {
        type AnswerQuestionOutput,
        getQuestionAnswerer,
        TooShortError,
    } from '../service/askQuestion';
    import { _ } from 'svelte-i18n';
    import questionButton from '../../landingpage/assets/question.svg';
    import LoadingGlobe from '../../kiosk/components/LoadingGlobe.svelte';
    import { Observable } from 'rxjs';
    import type { AuthenticatorState } from '../createPresentation/logic/stateMachine';

    export let presentationId: string;
    export let authenticator: Observable<AuthenticatorState>;

    const dispatch = createEventDispatcher<{
        answer: AnswerQuestionOutput;
        error: unknown;
        buttonPressed: void;
        buttonReleased: void;
    }>();
    let status: 'idle' | 'recording' | 'error' | 'processing' = 'idle';
    const { startRecording, stopRecording } = getQuestionAnswerer(
        presentationId,
        authenticator,
        (output) => {
            dispatch('answer', output);
            status = 'idle';
        },
        (error) => {
            if (error instanceof TooShortError) {
                status = 'idle';
                return; // ignore
            }
            dispatch('error', error);
            status = 'error';
        }
    );
    const startQuestionRecording = () => {
        dispatch('buttonPressed');
        startRecording();
        status = 'recording';
    };
    const stopQuestionRecording = () => {
        dispatch('buttonReleased');
        stopRecording();
        status = 'processing';
    };
</script>

<slot {status} {startQuestionRecording} {stopQuestionRecording}>
    {#if status === 'error'}
        <div class="error-message">
            {$_('presentation.answerQuestionFailed')}
        </div>
    {:else}
        <button
            disabled={status === 'processing'}
            class="question-button"
            on:mousedown={startQuestionRecording}
            on:mouseup={stopQuestionRecording}
        >
            {#if status === 'processing'}
                <span class="center-spinner">
                    <LoadingGlobe />
                </span>
            {/if}
            <img
                class="icon {status === 'recording'
                    ? 'heartbeat'
                    : ''} {status === 'processing' ? 'hidden' : ''}"
                src={questionButton}
                alt=""
            />
            <span class={status === 'processing' ? 'hidden' : ''}
                >{$_('presentation.actions.askQuestion')}</span
            ></button
        >
    {/if}
</slot>

<style>
    .error-message {
        color: red;
        border: 0.3rem solid red;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 1rem;
        padding: 1rem;
        z-index: 100;
    }

    .question-button {
        all: unset;
        position: relative;
        cursor: pointer;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 0.3rem solid var(--bs-primary);
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 1rem;
        padding: 1rem;
        z-index: 100;
    }

    .icon {
        width: 10dvw;
    }

    .heartbeat {
        animation: heartbeat 1.5s ease-in-out infinite;
    }

    @keyframes heartbeat {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    .hidden {
        opacity: 0;
    }

    .center-spinner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
