<script lang="ts">
    import PrivateRoute from '../../lib/guard/PrivateRoute.svelte';
    import New from '../common/New.svelte';
    import backgroundURL from '../../../common/assets/wrapping-paper.webp';
    import robot from '../../../common/assets/robot_santa.webp';
    import Redirect from '../../../common/Redirect.svelte';
    import { SimplePresentationKind } from '../../../common/types';
    import { _ } from 'svelte-i18n';

    const thinkingSymbols = '🤶⛄🦌🏡🎶🎀🔥🍬🍭🎊🎄🎅🎁🌟🔔🎉🍪🥛🧦';
</script>

<div
    class="page-wrapper story"
    style={`background-image:url('${backgroundURL}')`}
>
    <h1 class="heading">{$_('story.christmasTitle')}</h1>
    <PrivateRoute path="new">
        <New
            creatorImage={robot}
            thinkingCharCodes={thinkingSymbols}
            thinkingLength={4}
            storyKind={SimplePresentationKind.CHRISTMAS_STORY}
        />
    </PrivateRoute>
    <PrivateRoute path="*">
        <Redirect to={`/xmas/new`} replace={true} />
    </PrivateRoute>
</div>

<style>
    @font-face {
        font-family: StoryMainFont;
        src: url('../../../common/assets/sour-gummy.ttf');
    }

    :global(.story .text) {
        font-family: StoryMainFont, sans-serif;
    }

    :global(.story .heading) {
        font-family: StoryMainFont, sans-serif;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100svh;
        width: 100svw;
        position: fixed;
        top: 0;
        left: 0;
    }

    .heading {
        margin: 1rem;
        font-size: 3vw;
        color: #ffb221;
        box-shadow: 0 0 1rem 0.5rem #00000055;
        background-color: #00000055;
        border-radius: 3vw;
        padding: 1rem 2rem;
    }
</style>
