import { api } from '../api';
import type { components } from '../api/types';

const doAnswerQuestion = api.path('/api/question').method('post').create();

type QuestionAnswerEntry = components['schemas']['QuestionAnswerEntry'];

export async function answerQuestion(
    presentationId: string,
    audio: string,
    conversation: QuestionAnswerEntry[],
    token: string
) {
    return await doAnswerQuestion(
        { presentationId, audio, conversation },
        { headers: { authorization: `Bearer ${token}` } }
    );
}
