<script lang="ts">
    import { Route } from 'svelte-navigator';
    import PrivateRoute from '../../lib/guard/PrivateRoute.svelte';
    import Read from '../common/Read.svelte';
    import New from '../common/New.svelte';
    import backgroundURL from '../../../common/assets/wood.webp';
    import robot from '../../../common/assets/robot.png';
    import Redirect from '../../../common/Redirect.svelte';
    import { SimplePresentationKind } from '../../../common/types';
    import { _ } from 'svelte-i18n';

    const thinkingSymbols = Array.from({ length: 9718 - 9632 + 1 }, (_, i) =>
        String.fromCharCode(9632 + i)
    ).join('');
</script>

<div
    class="page-wrapper story"
    style={`background-image:url('${backgroundURL}')`}
>
    <h1 class="heading">{$_('story.childBookTitle')}</h1>
    <PrivateRoute path="new">
        <New
            creatorImage={robot}
            thinkingCharCodes={thinkingSymbols}
            storyKind={SimplePresentationKind.STORY}
        />
    </PrivateRoute>
    <Route path="read/:id" let:params>
        <Read id={params.id} baseStoryUrlPath="story" />
    </Route>
    <PrivateRoute path="*">
        <Redirect to={`/story/new`} replace={true} />
    </PrivateRoute>
</div>

<style>
    @font-face {
        font-family: StoryMainFont;
        src: url('../../../common/assets/sour-gummy.ttf');
    }

    :global(.story .text) {
        font-family: StoryMainFont, sans-serif;
    }

    :global(.story .heading) {
        font-family: StoryMainFont, sans-serif;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100svh;
        width: 100svw;
        position: fixed;
        top: 0;
        left: 0;
    }

    .heading {
        margin: 1rem;
        font-size: 3vw;
        color: #ffff00;
    }
</style>
