<script lang="ts">
    import tngLogo from '../../common/assets/tng.svg';
    import { Link } from 'svelte-navigator';
    import { _ } from 'svelte-i18n';
    import ResponsiveLanguageSwitcher from './ResponsiveLanguageSwitcher.svelte';
</script>

<footer>
    <ResponsiveLanguageSwitcher />
    <a class="link" href="https://tngtech.com">
        <img class="logo" src={tngLogo} alt="TNG Technology Consulting GmbH" />
    </a>
    <nav
        aria-label="Secondary menu"
        class="secondary-links navbar navbar-expand navbar-dark"
    >
        <ul class="navbar-nav">
            <li class="nav-item">
                <Link class="nav-link" to="imprint"
                    >{$_('landingpage.footer.imprint')}</Link
                >
            </li>
            <li class="nav-item">
                <Link class="nav-link" to="privacy"
                    >{$_('landingpage.footer.privacy')}</Link
                >
            </li>
        </ul>
    </nav>
</footer>

<style>
    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 3rem;
        box-sizing: border-box;
        font-size: 1.8rem;
        text-align: center;
        background-color: var(--bs-dark) !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .logo {
        height: 1.5rem;
    }

    .link:link,
    .link:visited {
        color: var(--primary-foreground);
    }

    .secondary-links {
        margin-right: 1rem;
        --bs-nav-link-font-size: 16px;
    }
</style>
