import { writable } from 'svelte/store';
import type { components } from '../../common/api/types';

type ArrayType<T extends Array<unknown>> = T extends Array<infer V> ? V : never;
export type Topics = Array<
    ArrayType<components['schemas']['PresentationList']> & {
        favourite?: boolean;
    }
>;
export const topics = writable<Topics>([]);

const LOCAL_STORAGE_KEY = 'ai-favourites';

function storeFavourites(favourites: Array<string>) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(favourites));
}

topics.subscribe((presentations) => {
    if (presentations.length > 0) {
        const favs = presentations
            .filter((presentation) => presentation.favourite)
            .map((presentation) => presentation.id);
        storeFavourites(favs);
    }
});

function loadFavourites(): Array<string> {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '[]');
}

function sortByFavourite(newPresentations: Topics) {
    newPresentations
        .sort((p1, p2) => Number.parseInt(p2.id) - Number.parseInt(p1.id))
        .sort((p1, p2) => (p1.favourite ? -1 : 0) + (p2.favourite ? 1 : 0));
}

export function loadPresentations(presentations: Topics) {
    const favs = loadFavourites();
    const newPresentations = presentations.map((presentation) => ({
        ...presentation,
        favourite: favs.includes(presentation.id),
    }));
    sortByFavourite(newPresentations);
    topics.set(newPresentations);
}

export function makeFavourite(id: string) {
    topics.update((presentations) => {
        const newPresentations = presentations.map((presentation) => ({
            ...presentation,
            favourite: presentation.favourite || presentation.id === id,
        }));
        sortByFavourite(newPresentations);
        return newPresentations;
    });
}

export function makeNotFavourite(id: string) {
    topics.update((presentations) => {
        const newPresentations = presentations.map((presentation) => ({
            ...presentation,
            favourite: presentation.favourite && presentation.id !== id,
        }));
        sortByFavourite(newPresentations);
        return newPresentations;
    });
}
