<script lang="ts">
    import { loginStatus, setNewPassword } from '../../store/loginV2';
    import { derived } from 'svelte/store';
    import { LoginStatus } from '../../../common/types';
    import { _ } from 'svelte-i18n';
    import { onMount } from 'svelte';
    import { Button, Input } from 'sveltestrap';

    // TODO: make submit on Enter work

    let modalElement: HTMLDialogElement;
    let password: string = '';
    let repeatedPassword: string = '';
    $: haveUserInput = Boolean(password) && Boolean(repeatedPassword);

    const errorMessage = derived(loginStatus, (loginStatus) => {
        if (loginStatus.status === LoginStatus.PASSWORD_CHANGE_FAILED) {
            console.error(loginStatus.error);
            return loginStatus.userError;
        } else {
            return undefined;
        }
    });

    function showLoginModal() {
        modalElement?.showModal();
    }

    function closeLoginModal(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        modalElement?.close();
        password = '';
        repeatedPassword = '';
    }

    function changePassword(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        setNewPassword.next({ password, repeatedPassword });
    }

    function onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            changePassword(event);
        }
        if (event.key === 'Escape') {
            closeLoginModal(event);
        }
    }

    onMount(() => {
        showLoginModal();
    });
</script>

<Button color="danger" on:click={showLoginModal}>
    {$_('landingpage.login.changePassword')}
</Button>

<dialog class="login-modal" bind:this={modalElement}>
    {#if $errorMessage}
        <div class="error">{$errorMessage}</div>
    {/if}
    <form on:keydown={onKeyDown}>
        <span class="inputs">
            <label class="label" for="password"
                >{$_('landingpage.login.newPassword')}</label
            >
            <Input type="password" bind:value={password} required />
            <label class="label" for="repeated-password"
                >{$_('landingpage.login.repeatNewPassword')}</label
            >
            <Input type="password" bind:value={repeatedPassword} required />
        </span>
        <span class="actions">
            <Button color="danger" on:click={closeLoginModal}
                >{$_('landingpage.login.close')}</Button
            >
            <Button
                color="primary"
                disabled={!haveUserInput}
                on:click={changePassword}
                >{$_('landingpage.login.saveNewPassword')}</Button
            >
        </span>
    </form>
</dialog>

<style>
    .error {
        color: #f00;
        padding: 0;
        text-align: center;
        font-weight: 600;
    }

    .actions {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }

    .inputs {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0.5rem;
        align-items: center;
    }

    .inputs .label {
        text-align: right;
    }

    .login-modal {
        width: 90%;
        max-width: 400px;
        border: 0.1rem solid var(--primary-foreground);
        border-radius: 0.5rem;
        box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.4);
    }

    .login-modal::backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }

    @media (max-width: 400px) {
        .login-modal {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            border: none;
        }

        .actions {
            flex-direction: column-reverse;
        }

        .inputs {
            grid-template-columns: 1fr;
        }

        .inputs .label {
            text-align: left;
        }
    }
</style>
