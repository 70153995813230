import { createHashHistory, type To, type Update } from 'history';
import type { HistorySource } from 'svelte-navigator';

export type HistoryListener = (loc: Update) => void;

export function createHashSource(): HistorySource {
    const history = createHashHistory(window);
    let listeners: Array<HistoryListener> = [];

    history.listen((location) => {
        if (history.action === 'POP') {
            listeners.forEach((listener) => listener(location));
        }
    });

    return {
        get location() {
            return history.location as unknown as Location;
        },
        addEventListener(name: 'popstate', handler: HistoryListener) {
            if (name !== 'popstate') return;
            listeners.push(handler);
        },
        removeEventListener(name: 'popstate', handler: HistoryListener) {
            if (name !== 'popstate') return;
            listeners = listeners.filter((fn) => fn !== handler);
        },
        history: {
            get state() {
                return history.location.state as object;
            },
            pushState(state: any | undefined, _: unknown, uri: To) {
                history.push(uri, state);
            },
            replaceState(state: any | undefined, _: unknown, uri: To) {
                history.replace(uri, state);
            },
            go(to: number) {
                history.go(to);
            },
        },
    };
}
