<script lang="ts">
    import LoginSection from './login/LoginSection.svelte';
    import {
        Collapse,
        Navbar,
        NavbarToggler,
        NavbarBrand,
        Nav,
        NavItem,
        NavLink,
    } from 'sveltestrap';
    import { loginStatus } from '../store/loginV2.js';
    import { LoginStatus } from '../../common/types';
    import { _ } from 'svelte-i18n';

    let isOpen = false;

    function handleUpdate(event: CustomEvent<boolean>) {
        isOpen = event.detail;
    }
</script>

<Navbar color="dark" dark expand="md" container={false} class="w-100">
    <NavbarBrand href="/" class="ms-2">AI Slides</NavbarBrand>
    <NavbarToggler on:click={() => (isOpen = !isOpen)} class="me-2" />
    <Collapse
        {isOpen}
        color="dark"
        navbar
        expand="md"
        on:update={handleUpdate}
        class="bg-dark mt-2"
    >
        <Nav navbar class="p-2">
            <NavItem>
                <NavLink href="/#/">{$_('landingpage.menu.home')}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink href="/#/add-suggestion"
                    >{$_('landingpage.menu.suggestTopic')}</NavLink
                >
            </NavItem>
            {#if $loginStatus.status === LoginStatus.LOGGED_IN}
                <NavItem>
                    <NavLink href="/#/list-suggestions"
                        >{$_('landingpage.menu.reviewSuggestions')}</NavLink
                    >
                </NavItem>
                <NavItem>
                    <NavLink href="/#/new-presentation"
                        >{$_('landingpage.menu.newPresentation')}</NavLink
                    >
                </NavItem>
                <NavItem>
                    <NavLink href="/#/story"
                        >{$_('landingpage.menu.newStory')}</NavLink
                    >
                </NavItem>
                <NavItem>
                    <NavLink href="/#/dark-tales"
                        >{$_('landingpage.menu.newDarkTale')}</NavLink
                    >
                </NavItem>
                <NavItem>
                    <NavLink href="/#/xmas"
                        >{$_('landingpage.menu.newChristmasTale')}</NavLink
                    >
                </NavItem>
                <NavItem>
                    <NavLink href="/#/party"
                        >{$_('landingpage.menu.newDallicParty')}</NavLink
                    >
                </NavItem>
            {/if}
        </Nav>
        <Nav class="ms-auto p-2" navbar>
            <LoginSection />
        </Nav>
    </Collapse>
</Navbar>
