import { loginStatus$ } from '../store/loginV2';
import { firstValueFrom, switchMap, take } from 'rxjs';
import { LoginStatus } from '../../common/types';
import { api } from '../../common/api';

const doGenerateTopic = api.path('/api/topic').method('post').create();

export async function generateTopic() {
    return firstValueFrom(
        loginStatus$.pipe(
            take(1),
            switchMap(async (loginStatus) => {
                if (loginStatus.status !== LoginStatus.LOGGED_IN) {
                    throw new Error(
                        'User is not logged in, cannot delete presentation'
                    );
                }
                try {
                    const response = await doGenerateTopic(
                        {},
                        {
                            headers: {
                                authorization: `Bearer ${loginStatus.token}`,
                                'content-type': '',
                            },
                        }
                    );

                    const topic = response.data;
                    return topic.topic;
                } catch (e) {
                    console.error(e);
                    return 'Why error handling is hard';
                }
            })
        )
    );
}
