export async function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
            resolve(reader.result as string);
        });
        reader.addEventListener('error', (event) => {
            console.error('error in blob to base64', event);
            status = 'error';
        });
        reader.readAsDataURL(blob);
    });
}
