import { webSocket } from 'rxjs/webSocket';
import { loginStatus$ } from '../store/loginV2';
import { map, switchMap, take, tap } from 'rxjs';
import {
    LoginStatus,
    type NewDallicPartySettings,
    type NewPersonSettings,
    type WebsocketMessage,
} from '../../common/types';

function getWebsocketUrl(token: string) {
    const isSecure = /https/.test(window.location.protocol);
    return `ws${isSecure ? 's' : ''}://${window.location.hostname}:${
        window.location.port
    }/presentation?jwt=${token}`;
}

export function createNewPerson(settings: NewPersonSettings) {
    return loginStatus$.pipe(
        take(1),
        map((status) => {
            if (status.status !== LoginStatus.LOGGED_IN) {
                throw new Error('User is not logged in!');
            }
            return status.token;
        }),
        switchMap((token) => {
            const url = getWebsocketUrl(token);
            const socket = webSocket<WebsocketMessage>(url);
            socket.next({
                type: 'create-person',
                payload: settings,
            });
            return socket.pipe(
                tap((msg) => {
                    if (msg.type === 'created-person') {
                        socket.complete();
                    }
                })
            );
        })
    );
}

export function createNewDallicParty(settings: NewDallicPartySettings) {
    return loginStatus$.pipe(
        take(1),
        map((status) => {
            if (status.status !== LoginStatus.LOGGED_IN) {
                throw new Error('User is not logged in!');
            }
            return status.token;
        }),
        switchMap((token) => {
            const url = getWebsocketUrl(token);
            const socket = webSocket<WebsocketMessage>(url);
            socket.next({
                type: 'create-dallic-party',
                payload: settings,
            });
            return socket.pipe(
                tap((msg) => {
                    if (msg.type === 'created-dallic-party') {
                        socket.complete();
                    }
                })
            );
        })
    );
}
