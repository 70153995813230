<script lang="ts">
    import TranslationIcon from '../../assets/i18n.svg';
    import { Input } from 'sveltestrap';
    import { _, dictionary, locale } from 'svelte-i18n';
    import { derived } from 'svelte/store';
    import { localization } from './index';
    import { createEventDispatcher } from 'svelte';

    export let withIcon: boolean = true;

    const dispatch = createEventDispatcher<{ changed: string }>();

    const selectedLocaleKey = derived(localization, (getKey) => {
        return getKey('key');
    });

    function changeLocale(event: Event) {
        if (event.target instanceof HTMLSelectElement) {
            const newLocale = event.target.value;
            locale.set(newLocale);
            dispatch('changed', newLocale);
        }
    }
</script>

{#if withIcon}
    <img class="icon" src={TranslationIcon} alt={$_('switchLabel')} />
{/if}
<Input type="select" value={$selectedLocaleKey} on:change={changeLocale}>
    {#each Object.entries($dictionary) as lang}
        <option value={lang[0]}>{lang[1].languageLabel}</option>
    {/each}
</Input>

<style>
    .icon {
        height: 2rem;
    }
</style>
