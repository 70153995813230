import staticConfig from './server/config.json';
import dynamicConfig from './aws-config.json';
import dynamicConfigDev from './aws-config-develop.json';
import dynamicConfigMuseum from './aws-config-museum.json';
import type { JWK } from 'jose';

export type Config = StaticConfig & {
    namePrefix: string;
    s3Bucket: string;
    secretName: string;
    userPoolClientId: string;
    userPoolId: string;
    restrictToSimplePresentations: boolean;
    presentationQuota: false | number;
    webauthn:
        | false
        | {
              credentialsAttribute: string;
              relyingParty: {
                  id: string;
                  name: string;
              };
              jwtPublicKey: JWK;
              jwtAlgorithm: string;
          };
};

enum Environment {
    PRODUCTION = 'production',
    DEVELOP = 'develop',
    MUSEUM = 'museum',
}

const dynamicConfigByEnv = {
    [Environment.PRODUCTION]: dynamicConfig,
    [Environment.DEVELOP]: dynamicConfigDev,
    [Environment.MUSEUM]: dynamicConfigMuseum,
} satisfies Record<Environment, unknown>;

const DeploymentPrefix = {
    [Environment.PRODUCTION]: 'PptAi' as const,
    [Environment.DEVELOP]: 'PptAiDev' as const,
    [Environment.MUSEUM]: 'PptAiMuseum' as const,
} satisfies Record<Environment, string>;

type StaticConfig = Omit<typeof staticConfig, 'deployment'> & {
    deployment: (typeof staticConfig)['deployment'][Environment];
};

export const DEPLOYMENT_ENVIRONMENT = 'DEPLOYMENT_ENVIRONMENT';

export function getStage() {
    const env = process.env.DEPLOYMENT_ENVIRONMENT;
    if (env === Environment.PRODUCTION) {
        return Environment.PRODUCTION;
    }
    if (env === Environment.MUSEUM) {
        return Environment.MUSEUM;
    }
    return Environment.DEVELOP;
}

export function getConfig<
    Prefix extends (typeof DeploymentPrefix)[Environment] = 'PptAi',
>(): Config {
    const env = getStage();
    const dConfig = dynamicConfigByEnv[env];
    const prefix = DeploymentPrefix[getStage()] as Prefix;
    const { deployment, ...conf } = staticConfig;
    return {
        ...conf,
        deployment: deployment[env],
        namePrefix: prefix,
        restrictToSimplePresentations: env === Environment.MUSEUM,
        webauthn:
            env === Environment.MUSEUM
                ? {
                      credentialsAttribute: 'publicKeyCred',
                      relyingParty: {
                          name: 'AI Slides',
                          id: 'museum.ai-slides.com',
                      },
                      jwtPublicKey: {
                          kty: 'RSA',
                          n: 'pBtvsB2-m2est16zTBLT0EL6hTp-7uaR6eMSiUSDMOl0k5SU7NWI0dYcb3PdWDp-lSUtmbOxxtGGLTD1NRe71TppIC8d6HFLNXKtCIz0CLOhhote_lG7JrEZkQIE_XgL4ZFOt_1TKC5XbtSfW1sX0j2Vl3nm35_Q_rcOoN31LMwsasX4w-4Vm6FrYMFlr3dmv8A9i_L7jRlYUOxBWsh6t1Jm3uiYwOzTPkH_R9_tpuatw7WqYhZ8o5U8_6HEqB4Yjlh3kg4TP4zp_X9NR_y266nn6taD1zNr8W1iGuzHoQZlDhAqdYhtboD87W4QCoU6MqiOn8EXqAhNilkJ-xGFoQ',
                          e: 'AQAB',
                      },
                      jwtAlgorithm: 'RS256',
                  }
                : false,
        presentationQuota: env === Environment.MUSEUM ? 600 : false,
        s3Bucket:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}BucketStack`]?.[`${prefix}bucketName`] ?? '',
        secretName:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}SecretStack`]?.[`${prefix}secretName`] ?? '',
        userPoolClientId:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}UserPoolStack`]?.[`${prefix}userPoolClientId`] ??
            '',
        userPoolId:
            // @ts-expect-error -- yeah yeah
            dConfig[`${prefix}UserPoolStack`]?.[`${prefix}userPoolId`] ?? '',
    };
}
