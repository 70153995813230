import type { components } from '../api/types';
import { type AudioRecording, recordAudio } from './recordAudio';
import { answerQuestion } from './answerQuestion';
import { blobToBase64 } from './blobToBase64';
import { firstValueFrom, Observable } from 'rxjs';
import type { AuthenticatorState } from '../createPresentation/logic/stateMachine';

export type AnswerQuestionOutput =
    components['schemas']['AnswerQuestionOutput'];
export type QuestionAnswerEntry = components['schemas']['QuestionAnswerEntry'];

export class TooShortError extends Error {
    constructor() {
        super(
            'Recording duration is too short. Please record at least 2 seconds.'
        );
    }
}

export function getQuestionAnswerer(
    presentationId: string,
    authenticator: Observable<AuthenticatorState>,
    onAnswer: (output: AnswerQuestionOutput) => void,
    onError?: (error: unknown) => void
) {
    const conversationHistory: QuestionAnswerEntry[] = [];
    let recordingStartTime: number = 0;
    let audioRecording: AudioRecording;
    const startRecording = () => {
        recordingStartTime = Date.now();
        audioRecording = recordAudio();
        audioRecording.result.then(
            async (blob) => {
                const recordingDuration = Date.now() - recordingStartTime;
                if (recordingDuration < 2000) {
                    onError?.(new TooShortError());
                    return;
                }
                const questionAudioBase64 = await blobToBase64(blob);
                const authenticatorState = await firstValueFrom(authenticator);
                if (!authenticatorState.authenticated) {
                    onError?.(new Error('Not authenticated'));
                    return;
                }
                try {
                    const result = await answerQuestion(
                        presentationId,
                        questionAudioBase64,
                        conversationHistory,
                        authenticatorState.token
                    );

                    conversationHistory.push({
                        question: result.data.question,
                        answer: result.data.answer,
                    });

                    console.log(conversationHistory);

                    onAnswer(result.data);
                } catch (error) {
                    onError?.(error);
                }
            },
            (error) => onError?.(error)
        );
    };
    const stopRecording = () => {
        audioRecording?.stopRecording();
    };

    return {
        startRecording,
        stopRecording,
    };
}
