<script lang="ts">
    import { createHistory, Route, Router } from 'svelte-navigator';
    import Navigation from './lib/Navigation.svelte';
    import Presentations from './lib/Presentations.svelte';
    import PrivateRoute from './lib/guard/PrivateRoute.svelte';
    import Footer from './lib/Footer.svelte';
    import AddSuggestion from './lib/AddSuggestion.svelte';
    import ManageSuggestions from './lib/ManageSuggestions.svelte';
    import PublishPresentation from './lib/PublishPresentation.svelte';
    import Imprint from './lib/Imprint.svelte';
    import Privacy from './lib/Privacy.svelte';
    import { createHashSource } from '../common/history';
    import Story from './book/story/Story.svelte';
    import DarkTale from './book/darktale/DarkTale.svelte';
    import ViewPresentation from './lib/ViewPresentation.svelte';
    import NewPresentation from './lib/NewPresentation.svelte';
    import Christmas from './book/christmas/Christmas.svelte';
    import DallicParty from './misc/DallicParty.svelte';

    export let url = '';
    const hash = createHistory(createHashSource());
</script>

<div class="app">
    <Router {url} history={hash}>
        <Route path="xmas/*">
            <Christmas />
        </Route>
        <Route path="story/*">
            <Story />
        </Route>
        <Route path="dark-tales/*">
            <DarkTale />
        </Route>
        <Route path="party/*">
            <DallicParty />
        </Route>
        <Route path="view/:id/*" let:params>
            <Route path="new">
                <ViewPresentation presentationId={params.id} isNew />
            </Route>
            <Route path="/">
                <ViewPresentation presentationId={params.id} />
            </Route>
        </Route>
        <Route path="*">
            <header>
                <Navigation />
            </header>
            <main class="main-area">
                <PrivateRoute path="new-presentation"
                    ><NewPresentation /></PrivateRoute
                >
                <PrivateRoute path="list-suggestions"
                    ><ManageSuggestions />
                </PrivateRoute>
                <PrivateRoute path="publish-presentation/:id" let:params
                    ><PublishPresentation presentationId={params.id} />
                </PrivateRoute>
                <Route path="add-suggestion"><AddSuggestion /></Route>
                <Route path="imprint"><Imprint /></Route>
                <Route path="privacy"><Privacy /></Route>
                <Route path="/"><Presentations /></Route>
            </main>
            <Footer />
        </Route>
    </Router>
</div>

<style>
    :global(:root) {
        --primary-foreground: #333333;
        --primary-background: #fff;
        --active-foreground: #333;
        --active-background: #1576ee;
    }

    @font-face {
        font-family: 'HeaderFont';
        font-style: normal;
        font-weight: 400;
        src: local('HeaderFont'),
            url('/font/Anton-Regular.ttf') format('truetype');
    }

    :global(html) {
        height: 100%;
    }

    :global(body) {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: var(--primary-foreground);
        background-color: var(--primary-background);
        height: 100%;
    }

    :global(h1) {
        font-weight: 600;
        font-size: 1.5rem;
    }

    :global(h2) {
        font-weight: 600;
        font-size: 1.2rem;
    }

    :global(#app) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #aaa;
        min-height: 100%;
    }

    .app {
        width: 100%;
        background-color: var(--primary-background);
        min-height: 100%;
        position: relative;
        padding-bottom: 3rem;
        box-sizing: border-box;
        overflow: hidden;
    }

    header {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        flex-wrap: wrap-reverse;
        color: white;
        background-color: var(--bs-dark-rgb) !important;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .main-area {
        padding-top: 4rem;
    }
</style>
