<script lang="ts">
    import { Col, Row } from 'sveltestrap';
</script>

<div class="wrapper">
    <h2>Imprint</h2>
    <h3>Contact</h3>
    <Row class="mb-4">
        <Col>
            <h4>Address</h4>
            <div>
                TNG Technology Consulting GmbH<br /><br />Beta-Straße 13<br
                />85774 Unterföhring<br />Germany<br />
            </div>
        </Col>
        <Col>
            <h4>E-Mail</h4>
            <div>info(at)tngtech.com</div>
        </Col>
        <Col>
            <h4>Telephone</h4>
            <div>+49 89 2158 996-0</div>
        </Col>
        <Col>
            <h4>Telefax</h4>
            <div>+49 89 2158 996-9</div>
        </Col>
    </Row>
    <h3>Company</h3>
    <Row class="mb-4">
        <Col>
            <h4>Managing Directors</h4>
            <div>
                Henrik Klagges<br />Dr. Robert Dahlke<br />Thomas Endres<br
                />(responsible according to § 5 TMG and § 18 para. 2 MStV
                respectively)
            </div>
        </Col>
        <Col>
            <h4>Chairman of the supervisory board</h4>
            <div>Christoph Stock</div>
        </Col>
        <Col>
            <h4>Ombudsman</h4>
            <div>
                Kanzlei Prof. Dr. Scherer<br />&amp; Partner mbB<br /><br
                />ombudsmann-tng<br />(at)<br />scherer-recht.de
            </div>
        </Col>
        <Col>
            <h4>Trade Register</h4>
            <div>HRB 135082,<br />Munich District Court</div>
        </Col>
    </Row>
    <Row class="mb-4">
        <Col>
            <h4>Place of Jurisdiction</h4>
            <div>Munich</div>
        </Col>
        <Col>
            <h4>European VAT ID#</h4>
            <div>DE212842911</div>
        </Col>
        <Col>
            <h4>DUNS Number</h4>
            <div>314309902</div>
        </Col>
        <Col>
            <h4>Business Liability Insurance</h4>
            <div>
                Allianz Versicherungs-Aktiengesellschaft,<br />10900 Berlin
            </div>
        </Col>
    </Row>
</div>

<style>
    .wrapper {
        margin-top: 1rem;
        padding: 1rem;
    }
</style>
