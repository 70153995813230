<script lang="ts">
    import { loginStatus, setCredentials } from '../../store/loginV2';
    import { derived } from 'svelte/store';
    import { LoginStatus } from '../../../common/types';
    import { _ } from 'svelte-i18n';
    import { Alert, Button, Input } from 'sveltestrap';

    let modalElement: HTMLDialogElement;
    let username: string = '';
    let password: string = '';
    $: haveUserInput = Boolean(username) && Boolean(password);

    const errorMessage = derived(loginStatus, (loginStatus) => {
        if (
            loginStatus.status === LoginStatus.LOGGED_OUT &&
            loginStatus.error
        ) {
            console.error(loginStatus.error);
            return 'Login failed!';
        }
        return undefined;
    });

    function showLoginModal() {
        modalElement?.showModal();
    }

    function closeLoginModal(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        modalElement?.close();
        username = '';
        password = '';
    }

    function logIn(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        setCredentials.next({
            username,
            password,
        });
    }

    function onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            logIn(event);
        }
        if (event.key === 'Escape') {
            closeLoginModal(event);
        }
    }
</script>

<Button color="primary" on:click={showLoginModal}
    >{$_('landingpage.login.login')}</Button
>

<dialog class="login-modal" bind:this={modalElement}>
    {#if $errorMessage}
        <Alert color="danger">{$errorMessage}</Alert>
    {/if}
    <form on:keydown={onKeyDown}>
        <span class="inputs">
            <label class="label" for="username"
                >{$_('landingpage.login.username')}</label
            >
            <Input id="username" required bind:value={username} type="text" />
            <label class="label" for="password"
                >{$_('landingpage.login.password')}</label
            >
            <Input
                id="password"
                required
                bind:value={password}
                type="password"
            />
        </span>
        <span class="actions">
            <Button color="danger" on:click={closeLoginModal}
                >{$_('landingpage.login.close')}</Button
            >
            <Button color="primary" on:click={logIn} disabled={!haveUserInput}
                >{$_('landingpage.login.login')}</Button
            >
        </span>
    </form>
</dialog>

<style>
    .actions {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }

    .inputs {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        gap: 0.5rem;
    }

    .inputs .label {
        text-align: center;
    }

    .login-modal {
        width: 90%;
        max-width: 400px;
        border: 0.2rem solid var(--bs-light);
        border-radius: 0.5rem;
        box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.1);
    }

    .login-modal::backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 400px) {
        .login-modal {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            border: none;
        }

        .actions {
            flex-direction: column-reverse;
        }

        .inputs {
            grid-template-columns: 1fr;
        }
    }

    .inputs .label {
        text-align: center;
    }
</style>
