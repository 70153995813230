import { loginStatus$ } from '../store/loginV2';
import { firstValueFrom, switchMap, take } from 'rxjs';
import { getTopics } from './topics';
import { LoginStatus } from '../../common/types';
import { api } from '../../common/api';

const doDeletePresentation = api
    .path('/api/presentation/{presentationId}')
    .method('delete')
    .create();

export function deletePresentation(presentationId: string) {
    return firstValueFrom(
        loginStatus$.pipe(
            take(1),
            switchMap((loginStatus) => {
                if (loginStatus.status !== LoginStatus.LOGGED_IN) {
                    throw new Error(
                        'User is not logged in, cannot delete presentation'
                    );
                }
                return doDeletePresentation(
                    { presentationId },
                    {
                        headers: {
                            authorization: `Bearer ${loginStatus.token}`,
                        },
                    }
                );
            }),
            switchMap(() => {
                return getTopics();
            })
        )
    );
}
