import { api } from '../api';

const doUploadPersonData = api.path('/api/personData').method('post').create();

export async function uploadPersonData(
    imageData: string | undefined,
    audioData: string | undefined,
    token: string
): Promise<{ imageFile: string | undefined; audioFile: string | undefined }> {
    if (imageData && audioData) {
        const data = (
            await doUploadPersonData(
                { imageData, audioData },
                { headers: { authorization: `Bearer ${token}` } }
            )
        ).data;

        console.log(data, typeof data);
        return data;
    } else {
        return { imageFile: undefined, audioFile: undefined };
    }
}
