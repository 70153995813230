<script lang="ts">
    import { Route } from 'svelte-navigator';
    import Party from './Party.svelte';
    import Redirect from '../../common/Redirect.svelte';
</script>

<div class="page-wrapper">
    <Route path="/">
        <Party id={undefined} />
    </Route>
    <Route path="/:id" let:params>
        <Party id={params.id} />
    </Route>
    <Route path="*">
        <Redirect to={`/`} replace={true} />
    </Route>
</div>
