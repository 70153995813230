<script lang="ts">
    import { Route } from 'svelte-navigator';
    import PrivateRouteGuard from '../../../common/PrivateRouteGuard.svelte';
    import { isLoggedIn } from '../../store/loginV2';

    export let path: string;
</script>

<Route {path} let:params let:location let:navigate>
    <PrivateRouteGuard {isLoggedIn}>
        <slot {params} {location} {navigate} />
    </PrivateRouteGuard>
</Route>
