<script>
    import LanguageSwitcher from '../../common/service/i18n/LanguageSwitcher.svelte';
    import TranslationIcon from '../../common/assets/i18n.svg';
    import { _ } from 'svelte-i18n';
    import { Dropdown, DropdownMenu, DropdownToggle } from 'sveltestrap';

    let isOpen = false;

    function closeDropdown() {
        isOpen = false;
    }

    function toggleDropdown() {
        isOpen = !isOpen;
    }
</script>

<div class="switcher">
    <LanguageSwitcher />
</div>
<div class="switcher-small">
    <Dropdown
        {isOpen}
        direction="up"
        class="h-100"
        autoClose="inside"
        toggle={toggleDropdown}
    >
        <DropdownToggle
            aria-label={$_('landingpage.footer.language')}
            class="h-100"
            color="dark"
        >
            <img class="icon" src={TranslationIcon} alt={$_('switchLabel')} />
        </DropdownToggle>
        <DropdownMenu>
            <div class="small-switcher-wrapper">
                <LanguageSwitcher withIcon={false} on:changed={closeDropdown} />
            </div>
        </DropdownMenu>
    </Dropdown>
</div>

<style>
    .switcher {
        display: none;
    }
    .switcher-small {
        height: 100%;
    }
    .icon {
        height: 100%;
    }
    .small-switcher-wrapper {
        padding: 0 0.5rem;
        width: 40svw;
    }
    /* bootstrap sm breakpoint */
    @media (min-width: 576px) {
        .switcher {
            display: flex;
            flex-direction: row;
            width: 10rem;
            align-items: center;
            height: 100%;
            gap: 0.5rem;
            padding-left: 1rem;
        }

        .switcher-small {
            display: none;
        }
    }
</style>
