import { loadPresentations } from '../store/topics';
import { api } from '../../common/api';

const doGetTopics = api.path('/api/presentation').method('get').create();

export async function getTopics() {
    try {
        const response = await doGetTopics({});

        const topics = response.data;
        loadPresentations(topics);
    } catch (e) {
        loadPresentations([]);
    }
}
