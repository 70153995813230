<script lang="ts">
    import Read from '../common/Read.svelte';
    import backgroundURL from '../../../../assets/wood.webp';
    import robot from '../../../../assets/robot.png';
    import type { SlideDisplayState } from '../../../logic/slideDisplay';

    export let slide: SlideDisplayState;
</script>

<div
    class="page-wrapper story"
    style={`background-image:url('${backgroundURL}')`}
>
    <Read slideState={slide} creatorImage={robot} />
</div>

<style>
    @font-face {
        font-family: StoryMainFont;
        src: url('../../../../assets/sour-gummy.ttf');
    }

    :global(.story .text) {
        font-family: StoryMainFont, sans-serif;
    }

    :global(.story .heading) {
        font-family: StoryMainFont, sans-serif;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100svh;
        width: 100svw;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>
