import { Fetcher } from 'openapi-typescript-fetch';
import type { paths } from './types';
import { schemas } from './decoder';
import { z } from 'zod';

export const api = Fetcher.for<paths>();

export { schemas as apiDecoder };

export type ImageGeneratorName = z.infer<
    (typeof schemas)['ImageGeneratorName']
>;
