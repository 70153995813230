<script lang="ts">
    import bubble from '../../assets/speechbubble.webp';
    import think from '../../assets/thinkingbubble.webp';
    import play from '../../assets/play.svg';
    import retry from '../../assets/retry.svg';
    import { Button, Form, FormGroup, Input } from 'sveltestrap';
    import { useNavigate } from 'svelte-navigator';
    import { _, json } from 'svelte-i18n';
    import CreatePresentation from '../../../common/createPresentation/CreatePresentation.svelte';
    import {
        type AuthenticatorState,
        type CreatePresentationState,
        CreatePresentationStateTag,
    } from '../../../common/createPresentation/logic/stateMachine';
    import { loginStatus$ } from '../../store/loginV2';
    import { map } from 'rxjs';
    import { LoginStatus, SimplePresentationKind } from '../../../common/types';
    import Thinking from '../../../common/createPresentation/kinds/book/Thinking.svelte';
    import { get } from 'svelte/store';
    import FloatingLanguageSwitcher from '../../../common/service/i18n/FloatingLanguageSwitcher.svelte';
    import RecordUserData from '../../../common/createPresentation/RecordUserData.svelte';

    export let creatorImage: string;
    export let storyKind: SimplePresentationKind;
    export let thinkingCharCodes: string;
    export let thinkingLength: number = 6;

    let title: string = '';
    let recordUser: RecordUserData;

    let personImage: string | undefined;
    let personAudio: string | undefined;

    const navigate = useNavigate();

    function goBack() {
        navigate(`/`);
    }

    function playStory(presentationId: string) {
        return () => navigate(`/view/${presentationId}/new`);
    }

    const authenticator = loginStatus$.pipe(
        map((loginStatus): AuthenticatorState => {
            if (loginStatus.status === LoginStatus.LOGGED_IN) {
                return {
                    authenticated: true,
                    token: loginStatus.token,
                };
            } else {
                return {
                    authenticated: false,
                };
            }
        })
    );

    function isErrorState(state: CreatePresentationStateTag) {
        return [
            CreatePresentationStateTag.ERROR,
            CreatePresentationStateTag.DENIED,
            CreatePresentationStateTag.QUOTA_EXCEEDED,
        ].includes(state);
    }

    async function startCapture(event: Event) {
        event.preventDefault();
        await recordUser.startCapture();
        return false;
    }

    function newPersonRecording(
        event: CustomEvent<{ encodedImage: string; encodedAudio: string }>
    ) {
        personImage = event.detail.encodedImage;
        personAudio = event.detail.encodedAudio;
    }

    function onSubmit(state: CreatePresentationState) {
        return (event: Event) => {
            event.preventDefault();
            if (state.tag === CreatePresentationStateTag.IDLE && title) {
                state.createPresentation(
                    {
                        title,
                        kind: storyKind,
                        language: get(json)(
                            'languageNameForGeneration'
                        ) as string,
                        person: {
                            image: personImage,
                            audio: personAudio,
                        },
                    },
                    'simple'
                );
            }
        };
    }

    function toArray(str: string) {
        return [...str];
    }
</script>

<div class="wrapper">
    <RecordUserData
        bind:this={recordUser}
        on:newRecording={newPersonRecording}
        style="story"
    />

    <CreatePresentation {authenticator} let:state>
        <div class="speaker-wrapper">
            <img src={creatorImage} alt="" class="waiting-speaker" />
            {#if state.tag === CreatePresentationStateTag.CREATED}
                <button
                    class="action-button play-button"
                    on:click={playStory(state.presentationId)}
                >
                    <img src={play} alt={$_('newStory.readOutButtonText')} />
                </button>
            {:else if isErrorState(state.tag)}
                <button
                    class="action-button retry-button"
                    on:click={state.retry}
                >
                    <img src={retry} alt={$_('newStory.retryButtonText')} />
                </button>
            {/if}
            {#if [CreatePresentationStateTag.IDLE, CreatePresentationStateTag.CREATED].includes(state.tag) || isErrorState(state.tag)}
                <img src={bubble} alt="" class="speech-bubble" />
                <h2 class="speaker-speech text">
                    {#if state.tag === CreatePresentationStateTag.IDLE}
                        {$_('newStory.createStory')}
                    {:else if state.tag === CreatePresentationStateTag.CREATED}
                        {$_('newStory.storyReady')}
                    {:else if isErrorState(state.tag)}
                        {$_('newStory.creationFailed')}
                    {/if}
                </h2>
            {:else if state.tag === CreatePresentationStateTag.CREATING}
                <img src={think} alt="" class="think-bubble" />
                <span class="thought">
                    <Thinking
                        length={thinkingLength}
                        thinkingCharCodes={toArray(thinkingCharCodes)}
                    />
                    <br />
                    <Thinking
                        length={thinkingLength}
                        thinkingCharCodes={toArray(thinkingCharCodes)}
                    />
                </span>
            {/if}
        </div>
        <div class="input-wrapper">
            <Form on:submit={onSubmit(state)}>
                <FormGroup floating label={$_('newStory.titleLabel')}>
                    <Input
                        bind:value={title}
                        class="text"
                        type="text"
                        name="topic"
                        id="topic"
                        bsSize="lg"
                        autofocus
                        maxlength={150}
                        required
                        readonly={state.tag !== CreatePresentationStateTag.IDLE}
                    />
                </FormGroup>
                <Input
                    type="submit"
                    value={$_('newStory.submitButtonText')}
                    disabled={!title ||
                        state.tag !== CreatePresentationStateTag.IDLE}
                    bsSize="lg"
                    color="primary"
                    class={state.tag !== CreatePresentationStateTag.IDLE
                        ? 'invisible'
                        : 'text'}
                />
                <Button
                    color="secondary"
                    on:click={startCapture}
                    size="lg"
                    class={state.tag !== CreatePresentationStateTag.IDLE
                        ? 'invisible'
                        : 'text'}
                >
                    {$_('record.startRecording')}
                </Button>
                <Button
                    color="secondary"
                    on:click={goBack}
                    size="lg"
                    class={state.tag !== CreatePresentationStateTag.IDLE
                        ? 'invisible'
                        : 'text'}
                >
                    {$_('story.backButtonText')}
                </Button>
            </Form>
        </div>
    </CreatePresentation>
</div>
<FloatingLanguageSwitcher />

<style>
    .input-wrapper {
        margin-bottom: 60svh;
        width: 60svw;
    }

    .speaker-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        scale: 1.2;
    }

    .waiting-speaker {
        position: absolute;
        bottom: 0;
        left: 10svh;
        transform: scaleX(-1);
        height: 30svh;
    }

    .speech-bubble {
        position: absolute;
        bottom: 27svh;
        left: 0;
        height: 20svh;
    }

    .think-bubble {
        position: absolute;
        bottom: 25svh;
        left: 12svh;
        height: 26svh;
    }

    .thought {
        position: absolute;
        bottom: 35.5svh;
        left: 16svh;
        font-size: 3.5svh;
        transform: rotate(10deg);
        font-family: monospace;
        letter-spacing: 0.6svh;
    }

    .speaker-speech {
        font-size: 3svh;
        position: absolute;
        bottom: 36.5svh;
        left: 5svh;
        text-align: center;
        transform: rotate(-3deg);
        width: 40svh;
    }

    .wrapper {
        position: relative;
        width: 90svw;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .action-button {
        all: unset;
        cursor: pointer;
        height: 8svh;
        width: 8svh;
        border-radius: 50%;
        position: absolute;
        bottom: 8svh;
        left: 21.5svh;
        padding: 0.5svh;
        box-sizing: border-box;
        filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.5));
    }

    .play-button {
        background-color: limegreen;
    }

    .play-button:hover {
        background-color: darkgreen;
    }

    .retry-button {
        background-color: crimson;
        padding: 1.5svh;
    }

    .retry-button:hover {
        background-color: darkred;
    }
</style>
