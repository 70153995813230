<script lang="ts">
    import { writable } from 'svelte/store';
    import { onMount } from 'svelte';

    export let length: number = 5;
    export let thinkingCharCodes: Array<string>;

    function generateRandomSymbol() {
        return thinkingCharCodes[
            Math.floor(Math.random() * thinkingCharCodes.length)
        ];
    }

    const thought = writable<Array<string>>(
        Array(length).fill(null).map(generateRandomSymbol)
    );

    onMount(() => {
        const interval = setInterval(() => {
            thought.update((current) => {
                // replace a random character with a new one
                const index = Math.floor(Math.random() * length);
                const newSymbol = generateRandomSymbol();
                return [
                    ...current.slice(0, index),
                    newSymbol,
                    ...current.slice(index + 1),
                ];
            });
        }, 100);

        return () => {
            clearInterval(interval);
        };
    });
</script>

<span class="no-break">
    {$thought.join('')}
</span>

<style>
    .no-break {
        white-space: nowrap;
    }
</style>
