<script lang="ts">
    import { onMount } from 'svelte';
    import type { SlideDisplayState } from './logic/slideDisplay';
    import { SlideDisplayTag } from './logic/slideDisplay';

    export let nextSlide: () => void;
    export let previousSlide: () => void;
    export let pause: () => void;
    export let play: () => void;
    export let restart: () => void;
    export let toggleSubtitles: () => void;

    export let slideState: SlideDisplayState;

    onMount(() => {
        function keyListener(event: KeyboardEvent) {
            switch (event.key) {
                case 'ArrowRight':
                    nextSlide();
                    break;
                case 'ArrowLeft':
                    previousSlide();
                    break;
                case 's':
                    toggleSubtitles();
                    break;
                case ' ':
                    if (
                        [
                            SlideDisplayTag.PAUSED,
                            SlideDisplayTag.LAST_SLIDE_PAUSED,
                            SlideDisplayTag.FIRST_SLIDE_PAUSED,
                        ].includes(slideState.tag)
                    ) {
                        play();
                    } else {
                        pause();
                    }
                    break;
                case 'r':
                    restart();
                    break;
            }
        }
        document.addEventListener('keydown', keyListener);

        return () => {
            document.removeEventListener('keydown', keyListener);
        };
    });
</script>
