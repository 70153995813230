import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

const PresentationKind = z.enum([
    'PRESENTATION',
    'STORY',
    'DARKTALE',
    'CHRISTMAS_STORY',
    'DALLIC_PARTY',
]);
const ImageGeneratorName = z.enum([
    'dalle-2',
    'dalle-3',
    'flux',
    'stable-diffusion-3',
    'mock',
]);
const PersonData = z
    .object({
        name: z.string(),
        personDescription: z.string(),
        imageDescription: z.string(),
        speaker: z.string(),
        image: z.string().optional(),
        audio: z.string().optional(),
    })
    .passthrough();
const NewPresentationSettings = z
    .object({
        kind: PresentationKind.optional(),
        title: z.string(),
        numberOfSlides: z.number().int(),
        narrationStyle: z.string().optional(),
        language: z.string(),
        createCoverSlideVideo: z.boolean(),
        createSubtitles: z.boolean().optional(),
        contentModeration: z.boolean(),
        promotion: z.string(),
        imageGeneratorName: ImageGeneratorName,
        speakerVideoGeneratorName: z.string(),
        textGeneratorName: z.string(),
        person: PersonData,
    })
    .passthrough();
const NewPresentation = z.object({ url: z.string() }).passthrough();
const PresentationList = z.array(
    z
        .object({
            url: z.string(),
            title: z.string(),
            id: z.string(),
            previewImage: z.string().optional(),
            language: z.string().optional(),
            speaker: z.string().optional(),
        })
        .passthrough()
);
const PersonDataUpload = z
    .object({ imageData: z.string(), audioData: z.string() })
    .passthrough();
const PersonDataFiles = z
    .object({ imageFile: z.string(), audioFile: z.string() })
    .passthrough();
const TitleSuggestion = z.object({ suggestion: z.string() }).passthrough();
const Suggestion = z
    .object({ suggestion: z.string(), id: z.string() })
    .passthrough();
const SuggestionList = z.array(Suggestion);
const QuestionAnswerEntry = z
    .object({ question: z.string(), answer: z.string() })
    .passthrough();
const AnswerQuestionInput = z
    .object({
        presentationId: z.string(),
        conversation: z.array(QuestionAnswerEntry),
        audio: z.string(),
    })
    .passthrough();
const AnswerQuestionOutput = z
    .object({
        question: z.string(),
        answer: z.string(),
        audioPath: z.string(),
        videoPath: z.string().optional(),
    })
    .passthrough();
const NewTopic = z.object({ topic: z.string() }).passthrough();
const AddAuthenticatorChallenge = z.string();
const Base64URLString = z.string();
const WebauthnAuthenticatorAssertionResponse = z
    .object({
        clientDataJSON: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        authenticatorData: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        signature: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        userHandle: z.string().optional(),
    })
    .passthrough();
const WebauthnAuthenticatorAttachment = z.enum(['cross-platform', 'platform']);
const WebauthnCredentialPropertiesOutput = z
    .object({ rk: z.boolean() })
    .partial()
    .passthrough();
const WebauthnAuthenticationExtensionsClientOutputs = z
    .object({
        appid: z.boolean(),
        credProps: WebauthnCredentialPropertiesOutput,
        hmacCreateSecret: z.boolean(),
    })
    .partial()
    .passthrough();
const WebauthnPublicKeyCredentialType = z.literal('public-key');
const WebauthnAuthenticationResponse = z
    .object({
        id: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        rawId: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        response: WebauthnAuthenticatorAssertionResponse,
        authenticatorAttachment: WebauthnAuthenticatorAttachment.optional(),
        clientExtensionResults: WebauthnAuthenticationExtensionsClientOutputs,
        type: WebauthnPublicKeyCredentialType,
    })
    .passthrough();
const WebauthnAuthenticatorTransportFuture = z.enum([
    'ble',
    'internal',
    'nfc',
    'usb',
    'cable',
    'hybrid',
]);
const WebauthnAuthenticatorAttestationResponse = z
    .object({
        clientDataJSON: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        attestationObject: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        transports: z.array(WebauthnAuthenticatorTransportFuture).optional(),
    })
    .passthrough();
const WebauthnRegistrationResponse = z
    .object({
        id: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        rawId: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        response: WebauthnAuthenticatorAttestationResponse,
        authenticatorAttachment: WebauthnAuthenticatorAttachment.optional(),
        clientExtensionResults: WebauthnAuthenticationExtensionsClientOutputs,
        type: WebauthnPublicKeyCredentialType,
    })
    .passthrough();
const AddAuthenticatorRequest = z
    .object({
        challenge: z.string(),
        existingAuthenticatorResponse: WebauthnAuthenticationResponse,
        newAuthenticatorResponse: WebauthnRegistrationResponse,
    })
    .passthrough();
const WebauthnPublicKeyCredentialRpEntity = z
    .object({ id: z.string().optional(), name: z.string() })
    .passthrough();
const WebauthnPublicKeyCredentialUserEntity = z
    .object({ id: z.string(), name: z.string(), displayName: z.string() })
    .passthrough();
const WebauthnPublicKeyCredentialParameters = z
    .object({ alg: z.number(), type: WebauthnPublicKeyCredentialType })
    .passthrough();
const WebauthnPublicKeyCredentialDescriptor = z
    .object({
        id: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        type: WebauthnPublicKeyCredentialType,
        transports: z.array(WebauthnAuthenticatorTransportFuture).optional(),
    })
    .passthrough();
const WebauthnResidentKeyRequirement = z.enum([
    'discouraged',
    'preferred',
    'required',
]);
const WebauthnUserVerificationRequirement = z.enum([
    'discouraged',
    'preferred',
    'required',
]);
const WebauthnAuthenticatorSelectionCriteria = z
    .object({
        authenticatorAttachment: WebauthnAuthenticatorAttachment,
        requireResidentKey: z.boolean(),
        residentKey: WebauthnResidentKeyRequirement,
        userVerification: WebauthnUserVerificationRequirement,
    })
    .partial()
    .passthrough();
const WebauthnAttestationConveyancePreference = z.enum([
    'direct',
    'enterprise',
    'indirect',
    'none',
]);
const WebauthnAuthenticationExtensionsClientInputs = z
    .object({
        appid: z.string(),
        credProps: z.boolean(),
        hmacCreateSecret: z.boolean(),
    })
    .partial()
    .passthrough();
const WebauthnPublicKeyCredentialsCreationOptions = z
    .object({
        rp: WebauthnPublicKeyCredentialRpEntity,
        user: WebauthnPublicKeyCredentialUserEntity,
        challenge: Base64URLString.regex(/^[A-Za-z0-9_-]+$/),
        pubKeyCredParams: z.array(WebauthnPublicKeyCredentialParameters),
        timeout: z.number().int().optional(),
        excludeCredentials: z
            .array(WebauthnPublicKeyCredentialDescriptor)
            .optional(),
        authenticatorSelection:
            WebauthnAuthenticatorSelectionCriteria.optional(),
        attestation: WebauthnAttestationConveyancePreference.optional(),
        extensions: WebauthnAuthenticationExtensionsClientInputs.optional(),
    })
    .passthrough();
const AddAuthenticatorChallengeClaims = z
    .object({
        allowedAuthenticators: z.array(z.string()),
        registrationOptions: WebauthnPublicKeyCredentialsCreationOptions,
    })
    .passthrough();
const Empty = z.object({}).partial().passthrough();
const NewPersonSettings = z
    .object({
        name: z.string(),
        personDescription: z.string(),
        imageDescription: z.string(),
        speaker: z.string(),
        textGeneratorName: z.string(),
    })
    .passthrough();
const SimplePersonData = z
    .object({ image: z.string(), audio: z.string() })
    .partial()
    .passthrough();
const SimplePresentationKind = z.enum([
    'PRESENTATION_ERNEST',
    'PRESENTATION_FUNNY',
    'STORY',
    'DARK_TALE',
    'CHRISTMAS_STORY',
    'DALLIC_PARTY',
]);
const SimpleNewPresentationSettings = z
    .object({
        title: z.string(),
        kind: SimplePresentationKind,
        language: z.string(),
        person: SimplePersonData,
    })
    .passthrough();
const PresentationTone = z.enum(['ERNEST', 'FUNNY']);
const DropdownOption = z
    .object({ label: z.string(), value: z.string() })
    .passthrough();
const PersonalityOptions = z
    .object({
        label: z.string(),
        value: z.string(),
        name: z.string().optional(),
        personDescription: z.string().optional(),
        imageDescription: z.string().optional(),
        speaker: z.string().optional(),
        showInFrontend: z.boolean().optional(),
    })
    .passthrough();
const SpeakerOption = z
    .object({ label: z.string(), value: z.string(), language: z.string() })
    .passthrough();
const NewPresentationOptions = z
    .object({
        languages: z.array(DropdownOption),
        personalities: z.array(PersonalityOptions),
        speakers: z.array(SpeakerOption),
        imageGenerators: z.array(DropdownOption),
        speakerVideoGenerators: z.array(DropdownOption),
        textGenerators: z.array(DropdownOption),
        slides: z.array(DropdownOption),
    })
    .passthrough();
const NewDallicPartySettings = z
    .object({
        initialPrompt: z.string(),
        changePrompt: z.string(),
        numberOfIterations: z.number().int(),
        textGeneratorName: z.string(),
        imageGeneratorName: ImageGeneratorName,
    })
    .passthrough();
const CreatePresentationMessage = z
    .object({
        type: z.literal('create-presentation'),
        payload: NewPresentationSettings,
    })
    .passthrough();
const SimpleCreatePresentationMessage = z
    .object({
        type: z.literal('create-presentation-simple'),
        payload: SimpleNewPresentationSettings,
    })
    .passthrough();
const CreateDallicPartyMessage = z
    .object({
        type: z.literal('create-dallic-party'),
        payload: NewDallicPartySettings,
    })
    .passthrough();
const CreatedPersonMessage = z
    .object({ type: z.literal('created-person'), payload: PersonData })
    .passthrough();
const CreatedDallicPartyMessage = z
    .object({ type: z.literal('created-dallic-party'), id: z.string() })
    .passthrough();
const ProgressMessage = z
    .object({
        type: z.literal('progress'),
        message: z.string(),
        data: z.object({}).partial().passthrough().optional(),
    })
    .passthrough();
const CreatedPresentationMessage = z
    .object({
        type: z.literal('created-presentation'),
        url: z.string(),
        id: z.string(),
    })
    .passthrough();
const AbortMessage = z
    .object({
        type: z.literal('abort'),
        message: z.string(),
        flaggingInformation: z.string(),
    })
    .passthrough();
const ErrorMessage = z
    .object({ type: z.literal('error'), message: z.string() })
    .passthrough();
const QuotaExceededMessage = z
    .object({ type: z.literal('quota') })
    .passthrough();
const CreatePersonMessage = z
    .object({ type: z.literal('create-person'), payload: NewPersonSettings })
    .passthrough();
const WebsocketMessage = z.union([
    CreatePresentationMessage,
    SimpleCreatePresentationMessage,
    CreateDallicPartyMessage,
    CreatedPersonMessage,
    CreatedDallicPartyMessage,
    ProgressMessage,
    CreatedPresentationMessage,
    AbortMessage,
    ErrorMessage,
    QuotaExceededMessage,
    CreatePersonMessage,
    CreatedPersonMessage,
    ProgressMessage,
    CreatedPresentationMessage,
    AbortMessage,
    ErrorMessage,
    QuotaExceededMessage,
]);

export const schemas = {
    PresentationKind,
    ImageGeneratorName,
    PersonData,
    NewPresentationSettings,
    NewPresentation,
    PresentationList,
    PersonDataUpload,
    PersonDataFiles,
    TitleSuggestion,
    Suggestion,
    SuggestionList,
    QuestionAnswerEntry,
    AnswerQuestionInput,
    AnswerQuestionOutput,
    NewTopic,
    AddAuthenticatorChallenge,
    Base64URLString,
    WebauthnAuthenticatorAssertionResponse,
    WebauthnAuthenticatorAttachment,
    WebauthnCredentialPropertiesOutput,
    WebauthnAuthenticationExtensionsClientOutputs,
    WebauthnPublicKeyCredentialType,
    WebauthnAuthenticationResponse,
    WebauthnAuthenticatorTransportFuture,
    WebauthnAuthenticatorAttestationResponse,
    WebauthnRegistrationResponse,
    AddAuthenticatorRequest,
    WebauthnPublicKeyCredentialRpEntity,
    WebauthnPublicKeyCredentialUserEntity,
    WebauthnPublicKeyCredentialParameters,
    WebauthnPublicKeyCredentialDescriptor,
    WebauthnResidentKeyRequirement,
    WebauthnUserVerificationRequirement,
    WebauthnAuthenticatorSelectionCriteria,
    WebauthnAttestationConveyancePreference,
    WebauthnAuthenticationExtensionsClientInputs,
    WebauthnPublicKeyCredentialsCreationOptions,
    AddAuthenticatorChallengeClaims,
    Empty,
    NewPersonSettings,
    SimplePersonData,
    SimplePresentationKind,
    SimpleNewPresentationSettings,
    PresentationTone,
    DropdownOption,
    PersonalityOptions,
    SpeakerOption,
    NewPresentationOptions,
    NewDallicPartySettings,
    CreatePresentationMessage,
    SimpleCreatePresentationMessage,
    CreateDallicPartyMessage,
    CreatedPersonMessage,
    CreatedDallicPartyMessage,
    ProgressMessage,
    CreatedPresentationMessage,
    AbortMessage,
    ErrorMessage,
    QuotaExceededMessage,
    CreatePersonMessage,
    WebsocketMessage,
};

const endpoints = makeApi([
    {
        method: 'post',
        path: '/api/auth/:userId/addAuthenticator',
        alias: 'postAddAuthenticator',
        requestFormat: 'json',
        parameters: [
            {
                name: 'body',
                type: 'Body',
                schema: AddAuthenticatorRequest,
            },
            {
                name: 'userId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.void(),
        errors: [
            {
                status: 401,
                description: `Request denied`,
                schema: z.void(),
            },
        ],
    },
    {
        method: 'get',
        path: '/api/auth/:userId/addAuthenticatorChallenge',
        alias: 'getAddAuthenticatorChallenge',
        description: `Retrieve a signed challenge which can be used to enroll a new authenticator`,
        requestFormat: 'json',
        parameters: [
            {
                name: 'userId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.string(),
    },
    {
        method: 'post',
        path: '/api/personData',
        alias: 'uploadPersonData',
        requestFormat: 'json',
        parameters: [
            {
                name: 'body',
                type: 'Body',
                schema: PersonDataUpload,
            },
        ],
        response: PersonDataFiles,
    },
    {
        method: 'get',
        path: '/api/presentation',
        alias: 'getPresentations',
        requestFormat: 'json',
        response: PresentationList,
        errors: [
            {
                status: 404,
                description: `presentations not found`,
                schema: z.void(),
            },
        ],
    },
    {
        method: 'delete',
        path: '/api/presentation/:presentationId',
        alias: 'deletePresentation',
        requestFormat: 'json',
        parameters: [
            {
                name: 'presentationId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.void(),
    },
    {
        method: 'put',
        path: '/api/presentation/:presentationId/pub',
        alias: 'publishPresentation',
        requestFormat: 'json',
        parameters: [
            {
                name: 'body',
                type: 'Body',
                schema: z.unknown().optional(),
            },
            {
                name: 'presentationId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.void(),
        errors: [
            {
                status: 401,
                description: `not authorized`,
                schema: z.void(),
            },
            {
                status: 404,
                description: `presentation not found`,
                schema: z.void(),
            },
        ],
    },
    {
        method: 'delete',
        path: '/api/presentation/:presentationId/pub',
        alias: 'unpublishPresentation',
        requestFormat: 'json',
        parameters: [
            {
                name: 'presentationId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.void(),
        errors: [
            {
                status: 401,
                description: `not authorized`,
                schema: z.void(),
            },
            {
                status: 404,
                description: `presentation not found`,
                schema: z.void(),
            },
        ],
    },
    {
        method: 'post',
        path: '/api/question',
        alias: 'answerQuestion',
        requestFormat: 'json',
        parameters: [
            {
                name: 'body',
                type: 'Body',
                schema: AnswerQuestionInput,
            },
        ],
        response: AnswerQuestionOutput,
    },
    {
        method: 'post',
        path: '/api/suggestion',
        alias: 'addSuggestion',
        requestFormat: 'json',
        parameters: [
            {
                name: 'body',
                type: 'Body',
                schema: z.object({ suggestion: z.string() }).passthrough(),
            },
        ],
        response: z.void(),
    },
    {
        method: 'get',
        path: '/api/suggestion',
        alias: 'getSuggestion',
        requestFormat: 'json',
        response: z.array(Suggestion),
    },
    {
        method: 'delete',
        path: '/api/suggestion/:suggestionId',
        alias: 'deleteSuggestion',
        description: `delete a suggestion`,
        requestFormat: 'json',
        parameters: [
            {
                name: 'suggestionId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.void(),
    },
    {
        method: 'post',
        path: '/api/topic',
        alias: 'generateTopic',
        requestFormat: 'json',
        response: z.object({ topic: z.string() }).passthrough(),
    },
    {
        method: 'post',
        path: '/new-slidedeck',
        alias: 'postNewSlidedeck',
        requestFormat: 'json',
        parameters: [
            {
                name: 'body',
                type: 'Body',
                schema: NewPresentationSettings,
            },
        ],
        response: z.object({ url: z.string() }).passthrough(),
    },
    {
        method: 'get',
        path: '/p/:presentationId',
        alias: 'getShortEntry',
        requestFormat: 'json',
        parameters: [
            {
                name: 'presentationId',
                type: 'Path',
                schema: z.string(),
            },
        ],
        response: z.void(),
        errors: [
            {
                status: 302,
                description: `redirect to the actual presentation`,
                schema: z.void(),
            },
        ],
    },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
    return new Zodios(baseUrl, endpoints, options);
}
