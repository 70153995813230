<script lang="ts">
    import { _ } from 'svelte-i18n';
    import turnDevice from '../assets/turn_device.svg';
</script>

<div role="alert" class="rotate-prompt">
    <img class="icon" src={turnDevice} alt="" />
    {$_('rotatePrompt')}
</div>

<style>
    .icon {
        width: 70dvw;
    }
    .rotate-prompt {
        display: none;
        position: fixed;
        height: 100dvh;
        width: 100dvw;
        background-color: black;
        color: white;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
    }
    @media (max-aspect-ratio: 1/1) {
        .rotate-prompt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            gap: 1rem;
            z-index: 100;
        }
    }
</style>
