<script lang="ts">
    import type { SlideDisplayState } from './logic/slideDisplay';
    import { SlideDisplayTag } from './logic/slideDisplay';
    import { onMount } from 'svelte';

    export let slideState: SlideDisplayState;
    export let nextSlide: () => void;
    export let play: () => void;
    export let autoPlay = false;
    export let slideTransitionDelay: number = 0;
    export let videoWidthVw: number = 20;

    let videoElement: HTMLVideoElement;
    const drawCanvas = new OffscreenCanvas(512, 512);
    let chromaCanvas: HTMLCanvasElement;

    let playbackPath: string | undefined = undefined;

    let currentTranscription: { text: string; start: number }[] = [];
    let subtitlesVisible = false;
    let currentSubtitleText = '';

    function goToNextSlide() {
        setTimeout(() => {
            nextSlide();
        }, slideTransitionDelay);
    }

    function clearCanvas() {
        chromaCanvas
            ?.getContext('2d')
            ?.clearRect(0, 0, chromaCanvas.width, chromaCanvas.height);
    }

    function chromaKey() {
        const drawContext = drawCanvas.getContext('2d');
        const chromaContext = chromaCanvas?.getContext('2d');
        if (!drawContext || !chromaContext) {
            return;
        }

        let width: number, height: number;
        let contextWidth: number, contextHeight: number;

        const initializeTimer = () => {
            if (!videoElement || videoElement.videoWidth === 0) {
                setTimeout(initializeTimer, 50);
                return;
            }

            width = videoElement.videoWidth;
            height = videoElement.videoHeight;

            contextWidth = drawCanvas.width;
            contextHeight = drawCanvas.height;

            timerCallback();
        };

        const timerCallback = () => {
            if (!videoElement || videoElement.paused || videoElement.ended) {
                return;
            }
            computeFrame();
            setTimeout(timerCallback, 10);
        };

        const computeFrame = () => {
            drawContext.drawImage(
                videoElement,
                0,
                0,
                width,
                height,
                0,
                0,
                contextWidth,
                contextHeight
            );
            let frame = drawContext.getImageData(
                0,
                0,
                contextWidth,
                contextHeight
            );
            let length = frame.data.length / 4;

            for (let i = 0; i < length; i++) {
                let r = frame.data[i * 4];
                let g = frame.data[i * 4 + 1];
                let b = frame.data[i * 4 + 2];
                if (g > 80 && g > b + r - 50) {
                    frame.data[i * 4 + 3] = 0;
                    frame.data[i * 4] = 0;
                    frame.data[i * 4 + 1] = 0;
                    frame.data[i * 4 + 2] = 0;
                }
            }

            chromaContext.putImageData(frame, 0, 0);
        };

        initializeTimer();
    }

    $: playing = [
        SlideDisplayTag.LAST_SLIDE_PLAYING,
        SlideDisplayTag.FIRST_SLIDE_PLAYING,
        SlideDisplayTag.PLAYING,
    ].includes(slideState.tag);

    $: {
        if (playing) {
            playbackPath =
                slideState.currentSlide.speakerVideoPath ??
                slideState.currentSlide.audioPath;
            videoElement?.play();
        } else if (slideState.tag === SlideDisplayTag.Q_AND_A) {
            playbackPath =
                slideState.answer.videoPath ?? slideState.answer.audioPath;
        } else if (
            [
                SlideDisplayTag.FIRST_SLIDE_PAUSED,
                SlideDisplayTag.PAUSED,
                SlideDisplayTag.LAST_SLIDE_PAUSED,
            ].includes(slideState.tag)
        ) {
            videoElement?.pause();
        } else {
            playbackPath = undefined;
        }
    }

    function updateSubtitles(slideState: SlideDisplayState) {
        // Sort by start time in descending order to that the first element we find with start < currentTime is the correct one
        currentTranscription =
            slideState.currentSlide.transcription?.sort(
                ({ start: a }, { start: b }) => b - a
            ) ?? [];
        subtitlesVisible =
            slideState.displayState.subtitlesVisible &&
            playing &&
            currentTranscription.length > 0;
    }

    function onTimeChange(event: Event) {
        if (
            event.target instanceof HTMLAudioElement ||
            event.target instanceof HTMLVideoElement
        ) {
            const playbackTime = event.target.currentTime ?? 0;
            currentSubtitleText =
                currentTranscription.find((transcriptionEntry) => {
                    return transcriptionEntry.start <= playbackTime;
                })?.text || '';
        } else {
            console.error(
                'target is neither an audio nor a video element',
                event
            );
        }
    }

    $: {
        updateSubtitles(slideState);
    }

    $: if (slideState.tag === SlideDisplayTag.LAST_SLIDE_FINISHED) {
        clearCanvas();
    }

    onMount(() => {
        if (autoPlay && !playing) {
            play();
        }
    });
</script>

{#if playbackPath}
    {#key playbackPath}
        <video
            class="speaker-video"
            bind:this={videoElement}
            autoplay
            src={playbackPath}
            on:ended={slideState.tag === SlideDisplayTag.Q_AND_A
                ? undefined
                : goToNextSlide}
            on:play={chromaKey}
            on:timeupdate={onTimeChange}
        />
    {/key}
{/if}

<canvas
    bind:this={chromaCanvas}
    id="chroma-canvas"
    width="512"
    height="512"
    style="position: absolute; right: 16.5vw; bottom: 0; width: {videoWidthVw}vw; z-index: 1000"
/>

<div id="subtitles" style="display: {subtitlesVisible ? 'block' : 'none'};">
    {currentSubtitleText}
</div>

<style>
    #subtitles {
        position: absolute;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 1%;
        border-radius: 2dvw;
        left: 0;
        top: 5%;
        font-family: Inter, sans-serif;
        font-size: 1.5dvw;
        text-align: center;
        color: #999999;
        background-color: #000000aa;
        z-index: 10000;
    }

    .speaker-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 30%;
        visibility: hidden;
    }
</style>
