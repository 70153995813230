<div class="wrapper">
    <h2>Privacy</h2>
    <p>
        TNG Technology Consulting GmbH is pleased about your visit to our
        website and your interest in our company. We take the protection of your
        personal data seriously and want you to feel comfortable and secure when
        visiting our Internet pages.
    </p>
    <h3>Collection and Processing of Personal Data</h3>
    <p>
        For purposes of delivery and optimization of our websites your IP
        address will be processed if you visit the pages of TNG Technology
        Consulting GmbH.
    </p>
    <h3>Use and Transmission of Personal Data and Appropriation</h3>
    <p>
        All personal data occurring in the context of TNG Technology Consulting
        GmbH's Internet services will be acquired, processed and used according
        to the stipulations applicable in each case regarding the protection of
        personal data, only for the objective of contract processing and to
        protect own legitimate financial interests with regard to the order
        processing of our customers and prospects.
    </p>
    <h3>Right to Information and Public Directory of Procedures</h3>
    <p>
        Upon request, in compliance with current legislation, we will inform you
        in writing whether any of your personal data is stored by us. If so, we
        will also inform you of the nature of this data.
    </p>
    <h3>Security</h3>
    <p>
        TNG Technology Consulting GmbH implements technical and organisational
        security measures to safeguard your data against inadvertent or
        deliberate manipulation, loss, or destruction and against access by
        unauthorised persons.
    </p>
    <p>
        Our security measures are continuously improved in line with
        technological progress.
    </p>
    <h3>Data Protection Representative</h3>
    <p>
        If you have any questions concerning the processing of your personal
        data, you can contact our representatives for data protection directly.
        They can also help you with information requests, applications, or
        complaints:
    </p>
    <p>
        Data Protection Representative<br />
        Daniel Wortmann<br />
        TNG Technology Consulting GmbH<br />
        Beta-Str. 13<br />
        85774 Unterföhring<br /><a
            href="mailto:datenschutz@tngtech.com"
            title="Open in email program"
            class="mail">datenschutz(at)tngtech.com</a
        >
    </p>
    <p>
        In case you want to send us your question in encrypted form, you will
        receive our public GPG key on request.
    </p>
</div>

<style>
    .wrapper {
        margin-top: 1rem;
        padding: 1rem;
    }
</style>
