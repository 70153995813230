import {
    AsyncSubject,
    interval,
    type Observable,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
} from 'rxjs';

export type AudioRecording = {
    result: Promise<Blob>;
    recordingTimeSeconds: Observable<number>;
    stopRecording: () => void;
};

export function formatSeconds(seconds: number): string {
    return `${Math.floor(seconds / 60).toFixed(0)}:${`0${seconds % 60}`.slice(-2)}`;
}

export function recordAudio(): AudioRecording {
    let mediaRecorder: MediaRecorder;
    const recordingTrigger = new AsyncSubject<void>();
    const recordingFinished = new AsyncSubject<void>();
    const result = new Promise<Blob>((resolve, reject) => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(
            (stream) => {
                const chunks: Array<BlobPart> = [];
                mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.addEventListener('dataavailable', (event) => {
                    chunks.push(event.data);
                });
                mediaRecorder.addEventListener('error', (event) => {
                    console.error('error in media recording', event);
                    reject(event);
                });
                mediaRecorder.addEventListener('stop', () => {
                    const audioBlob = new Blob(chunks, { type: 'audio/mp3' });
                    recordingFinished.next();
                    recordingFinished.complete();
                    resolve(audioBlob);
                });
                mediaRecorder.start();
                recordingTrigger.next();
                recordingTrigger.complete();
            },
            (error) => {
                reject(error);
                console.error('error in media recording', error);
            }
        );
    });

    return {
        result,
        recordingTimeSeconds: recordingTrigger.pipe(
            switchMap(() => {
                return interval(1000);
            }),
            startWith(0),
            takeUntil(recordingFinished),
            shareReplay(1)
        ),
        stopRecording: () => {
            if (mediaRecorder?.state === 'recording') {
                mediaRecorder?.stop();
            }
            mediaRecorder?.stream?.getTracks().forEach((track) => track.stop());
        },
    };
}
