import { writable } from 'svelte/store';
import { hardcodedPersons as personConfig } from '../../../../server/src/common/prompts/generationPrompts.json';
import type { components } from '../../common/api/types';
import type { ImageGeneratorName } from '../../common/api';

export type NewPresentationOptions =
    components['schemas']['NewPresentationOptions'];
export const newPresentationOptions = writable<NewPresentationOptions>({
    imageGenerators: [
        {
            label: 'Flux',
            value: 'flux',
        },
        {
            label: 'DALL·E 2',
            value: 'dalle-2',
        },
        {
            label: 'DALL·E 3',
            value: 'dalle-3',
        },
        {
            label: 'Stable Diffusion 3',
            value: 'stable-diffusion-3',
        },
    ] satisfies Array<{ label: string; value: ImageGeneratorName }>,
    speakerVideoGenerators: [
        {
            label: 'No Speaker Video',
            value: 'none',
        },
        {
            label: 'One Shot Talking Face',
            value: 'one-shot-talking-face',
        },
        {
            label: 'D-ID',
            value: 'd-id',
        },
    ],
    languages: [
        {
            label: 'English',
            value: 'en',
        },
        {
            label: 'Deutsch',
            value: 'de',
        },
    ],
    speakers: [
        {
            label: 'Joanna (en)',
            value: 'Joanna',
            language: 'English',
        },
        {
            label: 'Salli (en)',
            value: 'Salli',
            language: 'English',
        },
        {
            label: 'Kendra (en)',
            value: 'Kendra',
            language: 'English',
        },
        {
            label: 'Ivy (en)',
            value: 'Ivy',
            language: 'English',
        },
        {
            label: 'Kimberly (en)',
            value: 'Kimberly',
            language: 'English',
        },
        {
            label: 'Kevin (en)',
            value: 'Kevin',
            language: 'English',
        },
        {
            label: 'Matthew (en)',
            value: 'Matthew',
            language: 'English',
        },
        {
            label: 'Justin (en)',
            value: 'Justin',
            language: 'English',
        },
        {
            label: 'Joey (en)',
            value: 'Joey',
            language: 'English',
        },
        {
            label: 'Vicki (de)',
            value: 'Vicki',
            language: 'German',
        },
        {
            label: 'Hannah (de)',
            value: 'Hannah',
            language: 'German',
        },
        {
            label: 'Lea (fr)',
            value: 'Lea',
            language: 'French',
        },
        {
            label: 'Bianca (it)',
            value: 'Bianca',
            language: 'Italian',
        },
        {
            label: 'Takumi (jp)',
            value: 'Takumi',
            language: 'Japanese',
        },
        {
            label: 'Lupe (es)',
            value: 'Lupe',
            language: 'Spanish',
        },
        {
            label: 'Thomas (en)',
            value: 'Thomas',
            language: 'English',
        },
        {
            label: 'Thomas HT (en)',
            value: 'Thomas HT',
            language: 'English',
        },
        {
            label: 'Gramma (en)',
            value: 'Gramma',
            language: 'English',
        },
        /*{
            label: 'Chinston Wurchill (en)',
            value: 'Chinston Wurchill',
            language: 'English',
        },*/
        {
            label: 'Jonas (en)',
            value: 'Jonas',
            language: 'English',
        },
        {
            label: 'Martin (en)',
            value: 'Martin',
            language: 'English',
        },
        {
            label: 'Jenny Azure Angry (en)',
            value: 'Jenny Azure Angry',
            language: 'English',
        },
        {
            label: 'Jenny Azure Unfriendly (en)',
            value: 'Jenny Azure Unfriendly',
            language: 'English',
        },
        {
            label: 'Guy Azure Angry (en)',
            value: 'Guy Azure Angry',
            language: 'English',
        },
        {
            label: 'Guy Azure Shouting (en)',
            value: 'Guy Azure Shouting',
            language: 'English',
        },
        {
            label: 'Guy Azure Unfriendly (en)',
            value: 'Guy Azure Unfriendly',
            language: 'English',
        },
        {
            label: 'Denise Azure Cheerful (en)',
            value: 'Denise Azure Cheerful',
            language: 'English',
        },
        {
            label: 'Yunxi Azure Angry (en)',
            value: 'Yunxi Azure Angry',
            language: 'English',
        },
    ],
    personalities: Object.keys(personConfig).map((key) => {
        // @ts-ignore
        const entry = personConfig[key];
        return {
            value: key,
            label: entry.speakerDescription,
            name: entry.name,
            personDescription: entry.personDescription,
            imageDescription: entry.imageDescription,
            speaker: entry.speaker,
            showInFrontend: entry.showInFrontend,
        };
    }),
    textGenerators: [
        {
            label: 'Open AI GPT4 Omni',
            value: 'openai-gpt4-o',
        },
        {
            label: 'Open AI GPT4 Turbo',
            value: 'openai-gpt4-turbo',
        },
        {
            label: 'Open AI GPT4',
            value: 'openai-gpt4',
        },
        {
            label: 'Open AI GPT3',
            value: 'openai-gpt3',
        },
    ],
    slides: [
        {
            value: '3',
            label: '3 Slides',
        },
        {
            value: '2',
            label: '2 Slides',
        },
        {
            value: '5',
            label: '5 Slides',
        },
        {
            value: '10',
            label: '10 Slides',
        },
    ],
});
