<script lang="ts">
    import type { PresentationJson } from './logic/presentationJson';

    export let presentation: PresentationJson;

    const images = [
        presentation.initialSlide.imagePath,
        presentation.finalSlide.imagePath,
        ...presentation.slides.map((slide) => slide.imagePath),
    ].filter((image) => !!image);
    const videos = [
        presentation.initialSlide.speakerVideoPath,
        presentation.finalSlide.speakerVideoPath,
        ...presentation.slides.map((slide) => slide.speakerVideoPath),
    ].filter((video) => !!video);
    const audios = [
        presentation.initialSlide.audioPath,
        presentation.finalSlide.audioPath,
        ...presentation.slides.map((slide) => slide.audioPath),
    ].filter((audio) => !!audio);
</script>

<svelte:head>
    {#each images as image}
        <link rel="prefetch" href={image} as="image" />
    {/each}
    {#each videos as video}
        <link rel="prefetch" href={video} as="video" />
    {/each}
    {#each audios as audio}
        <link rel="prefetch" href={audio} as="audio" />
    {/each}
</svelte:head>
