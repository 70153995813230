<script lang="ts">
    import Read from '../common/Read.svelte';
    import backgroundUrl from '../../../../assets/dark-tales-background.png';
    import lady from '../../../../assets/lady.png';
    import type { SlideDisplayState } from '../../../logic/slideDisplay';

    export let slide: SlideDisplayState;
</script>

<div
    class="page-wrapper dark-tales"
    style={`background-image:url('${backgroundUrl}');'`}
>
    <Read creatorImage={lady} slideState={slide} />
</div>

<style>
    @font-face {
        font-family: MainHeaderFont;
        src: url('../../../../assets/abandon.ttf');
    }

    @font-face {
        font-family: TextFont;
        src: url('../../../../assets/seagram.ttf');
    }

    :global(.dark-tales .text) {
        font-family: TextFont, sans-serif;
        color: black;
    }

    :global(.dark-tales .heading) {
        font-family: MainHeaderFont, sans-serif;
        color: black;
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100svh;
        width: 100svw;
        background-size: 100% 100%;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>
