<script lang="ts">
    import { _ } from 'svelte-i18n';
    import { flip } from 'svelte/animate';
    import { makeFavourite, makeNotFavourite, topics } from '../store/topics';
    import { onMount } from 'svelte';
    import { getTopics } from '../actions/topics';
    import FavouriteStar from '../assets/heart_filled.svg';
    import NotFavouriteStar from '../assets/heart.svg';
    import TrashCan from '../assets/trash-can.svg';
    import Hide from '../assets/hide.svg';
    import { quintOut } from 'svelte/easing';
    import { crossfade } from 'svelte/transition';
    import { loginStatus } from '../store/loginV2';
    import { deletePresentation } from '../actions/deletePresentation';
    import { unpublishPresentation } from '../actions/unpublishPresentation';
    import { LoginStatus } from '../../common/types';
    import { get } from 'svelte/store';
    import VisibilityObserver from './VisibilityObserver.svelte';

    const numberOfTopicsToLoad = 21;
    let numberOfTopics = numberOfTopicsToLoad;

    const [__, receive] = crossfade({
        duration: (d) => Math.sqrt(d * 200),

        fallback(node) {
            const style = getComputedStyle(node);
            const transform = style.transform === 'none' ? '' : style.transform;

            return {
                duration: 600,
                easing: quintOut,
                css: (t) => `
					transform: ${transform} scale(${t});
					opacity: ${t}
				`,
            };
        },
    });

    const loadMore = () => {
        console.log('Loading more presentations');
        numberOfTopics = Math.min(
            numberOfTopics + numberOfTopicsToLoad,
            Math.max($topics.length, numberOfTopics)
        );
    };

    onMount(() => {
        if (!$topics || $topics.length === 0) {
            getTopics();
        }
    });

    function getBackground(imageUrl?: string) {
        return imageUrl
            ? `background-image: url("${imageUrl}");`
            : 'background-color: #2ab7ca';
    }

    function getFavouriteStyle(isFavourite?: boolean) {
        return `background-image: url("${
            isFavourite ? FavouriteStar : NotFavouriteStar
        }");`;
    }

    function getFavouriteAction(id: string, isFavourite?: boolean) {
        return isFavourite
            ? () => makeNotFavourite(id)
            : () => makeFavourite(id);
    }

    function getDeleteAction(id: string) {
        return () =>
            deletePresentation(id).then(
                () => {},
                (err) => {
                    console.error(err);
                    alert(get(_)('landingpage.presentations.failedToDelete'));
                }
            );
    }

    function getUnpublishAction(id: string) {
        return () =>
            unpublishPresentation(id).then(
                () => {},
                (err) => {
                    console.error(err);
                    alert(
                        get(_)('landingpage.presentations.failedToUnpublish')
                    );
                }
            );
    }

    const deleteButtonStyle = `background-image: url("${TrashCan}");`;
    const unpublishButtonStyle = `background-image: url("${Hide}");`;
</script>

<div class="presentation-container">
    <ul class="presentation-list">
        {#each $topics.slice(0, numberOfTopics) as topic (topic.id)}
            <li
                in:receive={{ key: topic.id }}
                animate:flip={{ duration: 300 }}
                class="presentation-item"
            >
                <a
                    class="presentation-link zoom"
                    href="/#/view/{topic.id}"
                    style={getBackground(topic.previewImage)}>&nbsp;</a
                >
                <a href="/#/view/{topic.id}" class="presentation-title"
                    >{topic.title}</a
                >
                <div class="button-container">
                    <button
                        class="button"
                        aria-label={$_(
                            topic.favourite
                                ? 'landingpage.presentations.unfavorite'
                                : 'landingpage.presentations.favorite'
                        )}
                        on:click={getFavouriteAction(topic.id, topic.favourite)}
                        style={getFavouriteStyle(topic.favourite)}
                    />
                    {#if $loginStatus.status === LoginStatus.LOGGED_IN}
                        <button
                            class="button"
                            aria-label={$_('landingpage.presentations.delete')}
                            on:click={getDeleteAction(topic.id)}
                            style={deleteButtonStyle}
                        />
                        <button
                            class="button"
                            aria-label={$_(
                                'landingpage.presentations.unpublish'
                            )}
                            on:click={getUnpublishAction(topic.id)}
                            style={unpublishButtonStyle}
                        />
                    {/if}
                </div>
            </li>
        {/each}
    </ul>
    <VisibilityObserver onVisible={loadMore} />
</div>

<style>
    .zoom {
        transition: transform 0.3s; /* Animation */
    }

    .zoom:hover {
        transform: scale(
            1.1
        ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }

    .presentation-container {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .presentation-list {
        width: 90%;
        margin-left: 5%;
        display: grid;
        list-style: none;
        padding: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(24.5rem, 1fr));
        grid-auto-rows: 21rem;
        gap: 1rem;
        place-items: center;
    }

    .presentation-item {
        box-shadow: 0.3rem 0.3rem 1rem 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        position: relative;
        width: 25rem;
        height: 19rem;
        border-radius: 1rem;
    }

    .presentation-item:hover {
        box-shadow: 0 0 1.5rem 0 rgba(21, 118, 238, 0.7);
    }

    .button {
        all: unset;
        position: absolute;
        height: 2rem;
        width: 2rem;
        border: none;
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;

        position: relative;
        background-position: center right;
        margin: 0.2rem;
    }

    .button:hover {
        background-color: rgba(255, 255, 255, 0.8);
        outline: 2px solid rgba(255, 255, 255, 0.8);
        border-radius: 0.5rem;
    }

    .button-container {
        width: 100%;
        height: 2rem;
        position: absolute;
        top: 0;
        padding: 1rem;
        background-position: center right;
    }

    .presentation-link {
        display: block;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;
        text-align: center;
    }

    .presentation-link:link,
    .presentation-link:visited {
        color: var(--primary-foreground);
        text-decoration: none;
    }

    .presentation-title {
        color: #444444;
        display: block;
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.95);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 1rem;
        box-sizing: border-box;
        text-decoration: none;
    }

    @media (max-width: 575.98px) {
        .presentation-list {
            width: 100%;
            margin-left: 0;
            display: grid;
            list-style: none;
            padding: 0rem;
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
            grid-template-columns: repeat(auto-fit, minmax(99vw, 1fr));
            grid-auto-rows: 75vw;
            gap: 0rem;
            place-items: center;
        }

        .presentation-item {
            box-shadow: 0.3rem 0.3rem 1rem 0 rgba(0, 0, 0, 0.2);
            overflow: hidden;
            position: relative;
            width: 95vw;
            height: 70vw;
            border-radius: 1rem;
        }

        .presentation-title {
            color: #444444;
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.95);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            font-weight: bold;
            padding: 0.75rem;
            box-sizing: border-box;
        }
    }
</style>
