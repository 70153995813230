<script lang="ts">
    export let image: string | undefined = undefined;
    export let text: string | undefined = undefined;
</script>

{#if image}
    <img class="book-image" src={image} alt="" />
    {#if text}
        <span class="image-headline text">{text}</span>
    {/if}
{:else if text}
    <span class="book-text text">{text}</span>
{/if}

<style>
    .book-text {
        font-size: 2.5svh;
        width: 80cqw;
        text-align: left;
        color: black;
    }

    .book-image {
        width: 80cqw;
    }

    .image-headline {
        font-size: 4svh;
        text-align: center;
        font-weight: bold;
        color: black;
    }
</style>
