<script lang="ts">
    import { _ } from 'svelte-i18n';
    import { Label, Form, FormGroup, Input, Button } from 'sveltestrap';
    import { Subject, tap } from 'rxjs';
    import { createNewDallicParty } from '../actions/newPresentation';
    import WithOskTextInput from '../../kiosk/components/WithOskTextInput.svelte';
    import { onMount } from 'svelte';
    import { loginStatus } from '../store/loginV2.js';
    import { LoginStatus } from '../../common/types';
    import Generating from '../lib/generate/Generating.svelte';
    import { useNavigate } from 'svelte-navigator';

    const navigate = useNavigate();

    const defaultChangePrompt = 'Make it more interesting';
    const defaultNumberOfIterations = 5;

    type ImageWithDescription = {
        imageUrl: string;
        description: string;
    };

    enum GeneratorStatus {
        IDLE,
        GENERATING,
        ERROR,
        ABORT,
    }

    let generateDallicPartyStatus: GeneratorStatus = GeneratorStatus.IDLE;

    let initialPrompt: string = '';
    let changePrompt: string = defaultChangePrompt;
    let numberOfIterations: number = defaultNumberOfIterations;
    export let steps: ImageWithDescription[] = [];

    let oldId: string | undefined = undefined;
    export let id: string | undefined = undefined;

    export let progressSubject: Subject<string> = new Subject<string>();

    async function reloadPresentation() {
        if (oldId === id) {
            return;
        }
        oldId = id;

        if (id) {
            const response = await fetch(`/presentations/${id}/index.json`);
            if (response.ok) {
                const content = await response.json();
                initialPrompt = content.initialPrompt;
                changePrompt = content.changePrompt;
                numberOfIterations = content.numberOfIterations;
                steps = content.steps;
            } else {
                id = undefined;
            }
        } else {
            initialPrompt = '';
            changePrompt = defaultChangePrompt;
            numberOfIterations = defaultNumberOfIterations;
            steps = [];
        }
    }

    onMount(async () => await reloadPresentation());

    function generateDallicParty(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        console.log('Generating');

        generateDallicPartyStatus = GeneratorStatus.GENERATING;

        steps = [];
        createNewDallicParty({
            initialPrompt: initialPrompt,
            changePrompt: changePrompt,
            numberOfIterations: numberOfIterations,
            textGeneratorName: 'openai-gpt4',
            imageGeneratorName: 'dalle-3',
        })
            .pipe(
                tap((msg) => {
                    console.log(msg);
                    if (msg.type === 'progress') {
                        progressSubject.next(msg.message);
                        if (msg.data && msg.data.type === 'image')
                            steps = [
                                ...steps,
                                {
                                    imageUrl: msg.data.imageUrl as string,
                                    description: msg.data.description as string,
                                },
                            ];
                    }
                })
            )
            .subscribe({
                next(message) {
                    if (message.type === 'error') {
                        generateDallicPartyStatus = GeneratorStatus.ERROR;
                    }
                    if (message.type === 'abort') {
                        generateDallicPartyStatus = GeneratorStatus.ABORT;
                    }
                    if (message.type == 'created-dallic-party') {
                        generateDallicPartyStatus = GeneratorStatus.IDLE;
                        id = message.id;
                    }
                },
                error(err) {
                    console.error(err);
                    generateDallicPartyStatus = GeneratorStatus.ERROR;
                },
                complete() {
                    navigate(`#/party/${id}`);
                },
            });

        return generateDallicPartyStatus;
    }

    function goPublish() {
        navigate(`/publish-presentation/${id}`);
    }

    function goBack() {
        navigate('/');
    }
</script>

<div class="page-wrapper">
    <div class="dallic-party">
        <h1>Dallic Party</h1>

        {#if $loginStatus.status === LoginStatus.LOGGED_IN}
            <div class="input-wrapper">
                <Form on:submit={generateDallicParty}>
                    <FormGroup class="text-black">
                        <Label for="initialPrompt" class="label">
                            {$_('newDallicParty.initialPrompt')}
                        </Label>
                        <WithOskTextInput
                            bind:value={initialPrompt}
                            class="text text-black"
                            placeholder={$_('newDallicParty.initialPrompt')}
                            type="text"
                            name="initialPrompt"
                            id="topic"
                            bsSize="lg"
                            autofocus
                            maxlength={150}
                            required
                            readonly={generateDallicPartyStatus !==
                                GeneratorStatus.IDLE}
                        />

                        <Label
                            for="changePrompt"
                            class="label"
                            style="margin-top: 1em;"
                        >
                            {$_('newDallicParty.changePrompt')}
                        </Label>
                        <WithOskTextInput
                            bind:value={changePrompt}
                            class="text text-black"
                            placeholder={$_('newDallicParty.changePrompt')}
                            type="text"
                            name="changePrompt"
                            id="changePrompt"
                            bsSize="lg"
                            autofocus
                            maxlength={150}
                            required
                            readonly={generateDallicPartyStatus !==
                                GeneratorStatus.IDLE}
                        />

                        <Label
                            for="numberOfIterations"
                            class="label"
                            style="margin-top: 1em; width: 79.5%;"
                        >
                            {$_('newDallicParty.numberOfIterations')}
                        </Label>
                        <Input
                            id="numberOfIterations"
                            name="numberOfIterations"
                            type="number"
                            bsSize="lg"
                            style="width: 20%; display: inline-block;"
                            min="2"
                            max="20"
                            required
                            bind:value={numberOfIterations}
                            readonly={generateDallicPartyStatus !==
                                GeneratorStatus.IDLE}
                        />
                    </FormGroup>
                    <Input
                        type="submit"
                        value={$_('newStory.submitButtonText')}
                        disabled={!initialPrompt ||
                            !changePrompt ||
                            generateDallicPartyStatus !== GeneratorStatus.IDLE}
                        bsSize="lg"
                        color="primary"
                        class="text"
                    />
                    <Button
                        color="secondary"
                        on:click={goBack}
                        size="lg"
                        disabled={generateDallicPartyStatus !==
                            GeneratorStatus.IDLE}
                        class="text"
                    >
                        {$_('story.backButtonText')}
                    </Button>
                    {#if id}
                        <Button
                            color="secondary"
                            on:click={goPublish}
                            size="lg"
                            disabled={generateDallicPartyStatus !==
                                GeneratorStatus.IDLE}
                            class="text"
                        >
                            {$_('landingpage.presentations.publish')}
                        </Button>
                    {/if}
                </Form>
            </div>
        {/if}

        <div class="container">
            {#each steps as item}
                <div class="item">
                    {#if item.imageUrl}
                        <img src={item.imageUrl} alt={item.description} />
                    {/if}
                    {#if item.description}
                        <div class="description">
                            {item.description}
                        </div>
                    {/if}
                </div>
            {/each}
        </div>

        {#if generateDallicPartyStatus === GeneratorStatus.GENERATING}
            <Generating progress={progressSubject} />
        {/if}
    </div>
</div>

<style>
    @font-face {
        font-family: DallicPartyMainFont;
        src: url('../../common/assets/sour-gummy.ttf');
    }

    .dallic-party {
        font-family: DallicPartyMainFont, sans-serif;
        padding-left: 20svw;
        padding-right: 20svw;
        width: 100%;
    }

    h1 {
        width: 100%;
        text-align: center;
        font-size: 3em;
        margin-top: 1em;
    }

    .input-wrapper {
        margin-top: 3em;
        font-size: 1.5em;
        width: 60svw;
    }

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 3em;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2em;
    }

    .item:nth-child(even) {
        flex-direction: row-reverse;
    }

    img {
        border-radius: 10px;
        margin: 0 2em;
        max-width: 50%;
    }

    .description {
        flex: 1;
        font-size: 1.5em;
    }
</style>
