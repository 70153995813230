<script lang="ts">
    import { _ } from 'svelte-i18n';

    let imageFileInput: HTMLInputElement;
    let audioFileInput: HTMLInputElement;

    export let encodedImage: string = '';
    export let encodedAudio: string = '';

    async function processFile(file: File, fileType: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!file.type.startsWith(`${fileType}/`)) {
                return '';
            }

            try {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    resolve(e.target?.result?.toString() || '');
                };
                reader.readAsDataURL(file);
            } catch (e) {
                reject(e);
            }
        });
    }

    async function handleImageChange(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            encodedImage = await processFile(input.files[0], 'image');
        }
    }

    async function handleAudioChange(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            encodedAudio = await processFile(input.files[0], 'audio');
        }
    }

    async function handleImageDrop(event: DragEvent) {
        event.preventDefault();
        if (event.dataTransfer?.files[0]) {
            encodedImage = await processFile(
                event.dataTransfer.files[0],
                'image'
            );
        }
    }

    async function handleAudioDrop(event: DragEvent) {
        event.preventDefault();
        if (event.dataTransfer?.files[0]) {
            encodedAudio = await processFile(
                event.dataTransfer.files[0],
                'audio'
            );
        }
    }

    function triggerImageFileInput(): void {
        imageFileInput.click();
    }

    function triggerAudioFileInput(): void {
        audioFileInput.click();
    }

    function handleDragOver(event: DragEvent): void {
        event.preventDefault();
    }
</script>

<div
    class="dropzone"
    on:click={triggerImageFileInput}
    on:drop|preventDefault={handleImageDrop}
    on:dragover|preventDefault={handleDragOver}
    aria-hidden="true"
>
    {$_('upload.dragDropImage')}
    <input
        bind:this={imageFileInput}
        type="file"
        accept="image/*"
        on:change={handleImageChange}
        hidden
    />
</div>

{#if encodedImage}
    <img src={encodedImage} alt={$_('upload.altImage')} />
{/if}

<div
    class="dropzone"
    on:click={triggerAudioFileInput}
    on:drop|preventDefault={handleAudioDrop}
    on:dragover|preventDefault={handleDragOver}
    aria-hidden="true"
>
    {$_('upload.dragDropAudio')}
    <input
        bind:this={audioFileInput}
        type="file"
        accept="audio/*"
        on:change={handleAudioChange}
        hidden
    />
</div>

{#if encodedAudio}
    <audio src={encodedAudio} controls>{$_('upload.audioNotSupported')}</audio>
{/if}

<style>
    .dropzone {
        margin: 2% 0;
        padding: 20px;
        border: 2px dashed #ccc;
        text-align: center;
        cursor: pointer;
    }

    audio {
        margin: 2% 0;
        width: 100%;
    }

    img {
        margin: 2% 25%;
        max-width: 50%;
    }
</style>
