import { writable } from 'svelte/store';
import { settings } from '../service/settings';

export const virtualKeyboardEnabled = writable(settings.onScreenKeyboard);

export const connectedInput = writable<
    | {
          element: HTMLInputElement;
          setValue: (val: string) => void;
      }
    | undefined
>(undefined);
