<script lang="ts">
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher<{
        retry: undefined;
        restart: undefined;
    }>();
</script>

<div class="wrapper">
    Generation has failed<br />
    <div class="error">Technical Error</div>
    <button
        on:click={() => {
            dispatch('retry');
        }}>try again?</button
    >
    <button
        on:click={() => {
            dispatch('restart');
        }}>try with a different prompt</button
    >
</div>

<style>
    .wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
    }
    .error {
        color: red;
        font-weight: bold;
    }
</style>
