<script lang="ts">
    import type { Observable } from 'rxjs';

    export let progress: Observable<string>;
</script>

<div class="loading">
    <div class="pl pl-leapfrog" />
    <div class="loading-text">generating...</div>
    <div class="message">{$progress ?? ''}</div>
</div>

<style>
    .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /** Global preloader styles **/
    .pl,
    .pl:before,
    .pl:after {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .pl {
        margin-top: 5rem;
        margin-bottom: 2rem;
        position: relative;
        width: 6rem;
        height: 6rem;
    }

    .pl:before,
    .pl:after {
        background: currentColor;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        transform-origin: 50% 100%;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
    }

    /* Leap Frog */
    .pl-leapfrog:before,
    .pl-leapfrog:after {
        clip-path: polygon(0 0, 100% 0, 0 100%);
        top: 50%;
        left: 50%;
        width: 70.71%;
        height: 70.71%;
        transform-origin: 0 0;
    }

    .pl-leapfrog:before {
        animation-name: leapFrogA;
    }

    .pl-leapfrog:after {
        animation-name: leapFrogB;
        background: #a3a3a3;
    }

    @keyframes leapFrogA {
        from {
            transform: rotateZ(-135deg) rotateY(0deg) rotateX(0deg);
            z-index: 0;
        }
        12.5% {
            transform: rotateZ(-135deg) rotateY(-180deg) rotateX(0deg);
            z-index: 1;
        }
        25%,
        50% {
            transform: rotateZ(-135deg) rotateY(-180deg) rotateX(-180deg);
            z-index: 0;
        }
        62.5% {
            transform: rotateZ(-135deg) rotateY(0deg) rotateX(-180deg);
            z-index: 1;
        }
        75%,
        to {
            transform: rotateZ(-135deg) rotateY(0deg) rotateX(-360deg);
            z-index: 0;
        }
    }

    @keyframes leapFrogB {
        from,
        25% {
            transform: rotateZ(-45deg) rotateY(0deg) rotateX(0deg);
            z-index: 0;
        }
        37.5% {
            transform: rotateZ(-45deg) rotateY(-180deg) rotateX(0deg);
            z-index: 1;
        }
        50%,
        75% {
            transform: rotateZ(-45deg) rotateY(-180deg) rotateX(-180deg);
            z-index: 0;
        }
        87.5% {
            transform: rotateZ(-45deg) rotateY(0deg) rotateX(-180deg);
            z-index: 1;
        }
        to {
            transform: rotateZ(-45deg) rotateY(0deg) rotateX(-360deg);
            z-index: 0;
        }
    }

    /** Media queries **/
    @media (prefers-color-scheme: dark) {
        .pl-leapfrog:after {
            background: #575757;
        }
    }
</style>
