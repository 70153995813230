import { SlideDisplayTag } from './slideDisplay';
import type { PresentationLogicState } from './pagerLogic';

export function reduceSlideStateToBool(state: PresentationLogicState['tag']) {
    const first =
        state === SlideDisplayTag.FIRST_SLIDE_PLAYING ||
        state === SlideDisplayTag.FIRST_SLIDE_PAUSED;
    const last =
        state === SlideDisplayTag.LAST_SLIDE_FINISHED ||
        state === SlideDisplayTag.LAST_SLIDE_PAUSED ||
        state === SlideDisplayTag.LAST_SLIDE_PLAYING ||
        state === SlideDisplayTag.Q_AND_A;
    const done =
        state === SlideDisplayTag.LAST_SLIDE_FINISHED ||
        state === SlideDisplayTag.Q_AND_A;
    return { first, last, done };
}
