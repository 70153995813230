<script lang="ts">
    import { suggestions } from '../store/suggestions';
    import { onMount } from 'svelte';
    import { getSuggestions } from '../actions/getSuggestions';
    import Suggestion from './Suggestion.svelte';
    import { _ } from 'svelte-i18n';

    onMount(() => {
        getSuggestions();
    });
</script>

<div class="wrapper">
    <h2>{$_('landingpage.manageSuggestions.title')}</h2>
    <ul>
        {#each $suggestions as suggestion}
            <Suggestion suggestion={suggestion.suggestion} id={suggestion.id} />
        {/each}
    </ul>
</div>

<style>
    .wrapper {
        margin-top: 1rem;
        padding: 1rem;
    }
</style>
