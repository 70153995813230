import { decodeJwt } from 'jose';

export function getTokenLifetime(jwt?: string): number {
    if (!jwt) {
        return -1;
    }
    try {
        const claims = decodeJwt(jwt);
        return (claims.exp ?? 0) * 1000 - Date.now();
    } catch (e) {
        return -1;
    }
}

export function isTokenCreatedToday(jwt?: string): boolean {
    if (!jwt) {
        return false;
    }
    try {
        const claims = decodeJwt(jwt);
        const creationDate = new Date((claims.iat ?? 0) * 1000);
        const now = new Date(Date.now());
        return (
            creationDate.getDate() === now.getDate() &&
            creationDate.getMonth() === now.getMonth() &&
            creationDate.getFullYear() === now.getFullYear()
        );
    } catch (e) {
        return false;
    }
}
