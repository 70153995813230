import type { PresentationKind } from '../logic/presentationJson';
import Presentation from './presentation/Presentation.svelte';
import Story from './book/story/Story.svelte';
import DarkTale from './book/darktale/DarkTale.svelte';
import Xmas from './book/xmas/Xmas.svelte';
import { apiDecoder } from '../../api';

export const PresentationByKind: Record<PresentationKind, typeof Presentation> =
    {
        [apiDecoder.PresentationKind.enum.PRESENTATION]: Presentation,
        [apiDecoder.PresentationKind.enum.STORY]: Story,
        [apiDecoder.PresentationKind.enum.DARKTALE]: DarkTale,
        [apiDecoder.PresentationKind.enum.CHRISTMAS_STORY]: Xmas,
        [apiDecoder.PresentationKind.enum.DALLIC_PARTY]: Presentation, // dummy
    };
