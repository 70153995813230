import { z } from 'zod';
import { apiDecoder } from '../../api';
import { schemas } from '../../api/decoder';

const slideDecoder = z.object({
    headline: z.string(),
    audioPath: z.string(),
    speakerVideoPath: z.string().optional(),
    imagePath: z.string(),
    text: z.string().catch(''),
    transcription: z
        .array(
            z.object({
                text: z.string(),
                start: z.number(),
            })
        )
        .optional(),
});

function transformSlide(indexURL: URL, slide: z.infer<typeof slideDecoder>) {
    return {
        ...slide,
        imagePath: new URL('./' + slide.imagePath, indexURL).toString(),
        audioPath: new URL('./' + slide.audioPath, indexURL).toString(),
        ...(slide.speakerVideoPath
            ? {
                  speakerVideoPath: new URL(
                      './' + slide.speakerVideoPath,
                      indexURL
                  ).toString(),
              }
            : {}),
    };
}

const getPresentationJsonDecoder = (indexURL: URL) =>
    z
        .object({
            kind: apiDecoder.PresentationKind.catch(
                apiDecoder.PresentationKind.enum.PRESENTATION
            ),
            initialSlide: slideDecoder.transform(
                ({ text, headline, ...rest }) => ({
                    ...rest,
                    headline: text,
                    text: headline,
                })
            ),
            finalSlide: slideDecoder,
            slides: z.array(slideDecoder),
            presentationId: z.string(),
            settings:
                schemas.NewPresentationSettings.optional().catch(undefined),
        })
        .transform(({ initialSlide, finalSlide, slides, ...props }) => ({
            initialSlide: transformSlide(indexURL, initialSlide),
            finalSlide: transformSlide(indexURL, finalSlide),
            slides: slides.map((slide) => transformSlide(indexURL, slide)),
            ...props,
        }));

export type PresentationKind = z.output<typeof apiDecoder.PresentationKind>;
export type PresentationJson = z.output<
    ReturnType<typeof getPresentationJsonDecoder>
>;
export type PresentationJsonSlide = z.output<typeof slideDecoder>;

export async function loadPresentationJson(presentationIndexURL: string) {
    try {
        const result = await fetch(presentationIndexURL);
        const data = await result.json();

        return getPresentationJsonDecoder(new URL(presentationIndexURL)).parse(
            data
        );
    } catch (e) {
        console.error('Error fetching presentation json', e);
        throw e;
    }
}
