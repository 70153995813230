<script lang="ts">
    import { addSuggestion } from '../actions/addSuggestion';
    import { _ } from 'svelte-i18n';

    enum State {
        NEW_SUGGESTION,
        SUBMITTED,
        ERROR,
    }
    let state: State = State.NEW_SUGGESTION;
    let suggestion: string = '';

    function nextSubmission() {
        state = State.NEW_SUGGESTION;
    }

    function submit(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        addSuggestion(suggestion).then(
            () => {
                suggestion = '';
                state = State.SUBMITTED;
            },
            () => {
                state = State.ERROR;
            }
        );
    }
</script>

<div class="wrapper">
    <h2>{$_('landingpage.suggestTopic.title')}</h2>
    {#if state === State.NEW_SUGGESTION}
        <form on:submit={submit}>
            <input
                class="suggestion"
                type="text"
                placeholder={$_('landingpage.suggestTopic.yourSuggestion')}
                bind:value={suggestion}
            /><br />
            <input
                class="button btn-primary"
                type="submit"
                value={$_('landingpage.suggestTopic.submitSuggestion')}
                disabled={!suggestion || suggestion.length < 3}
                maxlength="100"
            />
        </form>
    {:else if state === State.SUBMITTED}
        <p>{$_('landingpage.suggestTopic.suggestionSubmitted')}</p>
        <button class="button btn-primary" on:click={nextSubmission}
            >{$_('landingpage.suggestTopic.suggestAnother')}</button
        >
    {:else if state === State.ERROR}
        <p>{$_('landingpage.suggestTopic.submissionFailed')}</p>
        <button class="button btn-primary" on:click={nextSubmission}
            >{$_('landingpage.suggestTopic.retry')}</button
        >
    {/if}
</div>

<style>
    .wrapper {
        margin-top: 1rem;
        padding: 1rem;
    }

    .suggestion {
        padding: 0.3rem;
        width: 40rem;
        max-width: 90vw;
        box-sizing: border-box;
        margin-bottom: 1rem;
    }

    .button {
        padding: 0.5rem 1rem;
        border: 0.1rem solid var(--primary-foreground);
        border-radius: 0.25rem;
        background-color: var(--primary-background);
        cursor: pointer;
    }
</style>
