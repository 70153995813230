import { addMessages, getLocaleFromNavigator, init, json } from 'svelte-i18n';
import de from './de';
import en from './en';
import type { LocalizationSettings } from './types';
import type { Readable } from 'svelte/store';
import { get } from 'svelte/store';

const locales = new Map<string, LocalizationSettings>([
    [de.key, de],
    [en.key, en],
]);

export function initializeI18n() {
    locales.forEach((settings, lang) => {
        addMessages(lang, settings);
    });

    init({
        fallbackLocale: 'de',
        initialLocale: getLocaleFromNavigator(),
    });
}

export const localization = json as Readable<
    <Key extends keyof LocalizationSettings>(
        key: Key
    ) => LocalizationSettings[Key]
>;

export function getLocalizationSetting<Key extends keyof LocalizationSettings>(
    key: Key
) {
    return get(localization)(key);
}
