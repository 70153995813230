<script>
    import bookBackground from '../../../../assets/book_background.webp';
</script>

<div class="wrapper" style={`background-image: url('${bookBackground}')`}>
    <div class="content-wrapper">
        <div class="column-left">
            <slot name="lhs" />
        </div>
        <div class="column-right">
            <slot name="rhs" />
        </div>
    </div>
</div>

<style>
    .wrapper {
        height: 90svh;
        width: 90svw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .content-wrapper {
        height: 75svh;
        width: 100svh;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 5svh;
        box-sizing: border-box;
        padding-left: 1.3cqw;
        padding-right: 1.3cqw;
    }

    .column-left,
    .column-right {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1svh;
        container-type: size;
    }
</style>
