import {
    BehaviorSubject,
    filter,
    map,
    type Observer,
    Subject,
    withLatestFrom,
} from 'rxjs';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { derived, readable } from 'svelte/store';
import { getConfig } from '../../../../config';
import type { LoggedIn, UserLoginStatus } from '../../common/types';
import { LoginStatus } from '../../common/types';
import { getTokenLifetime } from '../../common/jwt';
import { fromLoginState } from '../../common/createPresentation/logic/stateMachine';

const config = getConfig();
const tokenKey = 'jwtToken';
const refreshTokenKey = 'refreshJwtToken';
const usernameKey = 'username';

const poolData = {
    UserPoolId: config.userPoolId,
    ClientId: config.userPoolClientId,
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export interface Credentials {
    username: string;
    password: string;
}

export interface NewPassword {
    password: string;
    repeatedPassword: string;
}

function getInitialLoginState(): UserLoginStatus {
    const storedToken = localStorage.getItem(tokenKey);
    const storedRefreshToken = localStorage.getItem(refreshTokenKey);
    const storedUsername = localStorage.getItem(usernameKey);
    if (storedToken && storedRefreshToken && storedUsername) {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: storedUsername,
            Pool: userPool,
        });

        return {
            status: LoginStatus.LOGGED_IN,
            token: storedToken,
            refreshToken: storedRefreshToken,
            user: cognitoUser,
        };
    }

    return {
        status: LoginStatus.LOGGED_OUT,
    };
}

function tryRefresh(
    user: AmazonCognitoIdentity.CognitoUser,
    refreshToken: string
) {
    const token = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: refreshToken,
    });
    user.refreshSession(token, (err, session) => {
        if (err) {
            $loginStatus.next({
                status: LoginStatus.LOGGED_OUT,
            });
        } else {
            $loginStatus.next({
                status: LoginStatus.LOGGED_IN,
                token: session.getAccessToken().getJwtToken(),
                refreshToken: refreshToken,
                user: user,
            });
        }
    });
}

const $loginStatus = new BehaviorSubject<UserLoginStatus>(
    getInitialLoginState()
);
$loginStatus
    .pipe(
        filter((status) => status.status === LoginStatus.LOGGED_IN),
        map((status) => status as LoggedIn)
    )
    .subscribe({
        next(status: LoggedIn) {
            try {
                const lifetime = getTokenLifetime(status.token);
                setTimeout(() => {
                    tryRefresh(status.user, status.refreshToken);
                }, lifetime);
            } catch (e) {
                $loginStatus.next({
                    status: LoginStatus.LOGGED_OUT,
                    error: e,
                });
            }
        },
    });

$loginStatus.subscribe({
    next(status) {
        if (status.status === LoginStatus.LOGGED_IN) {
            localStorage.setItem(tokenKey, status.token);
            localStorage.setItem(refreshTokenKey, status.refreshToken);
            localStorage.setItem(usernameKey, status.user.getUsername());
        } else {
            localStorage.removeItem(tokenKey);
            localStorage.removeItem(refreshTokenKey);
            localStorage.removeItem(usernameKey);
        }
    },
});
const $credentials = new Subject<Credentials>();
$credentials.subscribe({
    next({ username, password }) {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
            Username: username,
            Pool: userPool,
        });
        const credentials = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: username,
            Password: password,
        });

        cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

        cognitoUser.authenticateUser(credentials, {
            onSuccess(result) {
                $loginStatus.next({
                    status: LoginStatus.LOGGED_IN,
                    token: result.getAccessToken().getJwtToken(),
                    refreshToken: result.getRefreshToken().getToken(),
                    user: cognitoUser,
                });
            },
            onFailure(error) {
                $loginStatus.next({
                    status: LoginStatus.LOGGED_OUT,
                    error,
                });
            },
            newPasswordRequired() {
                $loginStatus.next({
                    status: LoginStatus.NEW_PASSWORD_REQUIRED,
                    user: cognitoUser,
                });
            },
        });
    },
});
const $newPassword = new Subject<NewPassword>();
$newPassword.pipe(withLatestFrom($loginStatus)).subscribe({
    next([newPassword, loginStatus]) {
        if (
            loginStatus.status === LoginStatus.NEW_PASSWORD_REQUIRED ||
            loginStatus.status === LoginStatus.PASSWORD_CHANGE_FAILED
        ) {
            if (newPassword.password !== newPassword.repeatedPassword) {
                $loginStatus.next({
                    status: LoginStatus.PASSWORD_CHANGE_FAILED,
                    user: loginStatus.user,
                    error: 'Passwords must match',
                    userError: 'Passwords must match',
                });
                return;
            }

            loginStatus.user.completeNewPasswordChallenge(
                newPassword.password,
                {},
                {
                    onSuccess(result) {
                        $loginStatus.next({
                            status: LoginStatus.LOGGED_IN,
                            token: result.getAccessToken().getJwtToken(),
                            refreshToken: result.getRefreshToken().getToken(),
                            user: loginStatus.user,
                        });
                    },
                    onFailure(error) {
                        $loginStatus.next({
                            status: LoginStatus.PASSWORD_CHANGE_FAILED,
                            user: loginStatus.user,
                            error,
                            userError: error.message,
                        });
                    },
                }
            );
        }
    },
});
const $requestLogout = new Subject<void>();
$requestLogout.pipe(withLatestFrom($loginStatus)).subscribe({
    next([_, loginStatus]) {
        if (loginStatus.status === LoginStatus.LOGGED_IN) {
            loginStatus.user.signOut(() => {
                $loginStatus.next({
                    status: LoginStatus.LOGGED_OUT,
                });
            });
        }
    },
});
export const setCredentials: Observer<Credentials> = $credentials;
export const setNewPassword: Observer<NewPassword> = $newPassword;
export const loginStatus$ = $loginStatus.asObservable();
export const loginStatus = readable($loginStatus.value, (set) => {
    $loginStatus.subscribe({
        next(status) {
            set(status);
        },
    });
});
export const isLoggedIn = derived(loginStatus, (status) => {
    return status.status === LoginStatus.LOGGED_IN;
});
export const requestLogout: Observer<void> = $requestLogout;

export const authenticator = loginStatus$.pipe(map(fromLoginState));
