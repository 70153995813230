<script lang="ts">
    import TrashCan from '../assets/trash-can.svg';
    import { deleteSuggestion } from '../actions/deleteSuggestion';
    import { _ } from 'svelte-i18n';

    export let suggestion: string;
    export let id: string;

    const deleteButtonStyle = `background-image: url("${TrashCan}");`;

    function removeSuggestion(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        deleteSuggestion(id);
    }
</script>

<li>
    {suggestion}
    <button
        class="delete-button"
        aria-label={$_('landingpage.manageSuggestions.delete')}
        style={deleteButtonStyle}
        on:click={removeSuggestion}
    />
</li>

<style>
    .delete-button {
        all: unset;
        border: none;
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        height: 1rem;
        width: 1rem;
        vertical-align: middle;
    }
</style>
