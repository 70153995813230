<script lang="ts">
    import { useNavigate } from 'svelte-navigator';

    const navigate = useNavigate();
    function returnHome() {
        navigate('/');
    }
</script>

<div class="wrapper">
    Generation has failed<br />
    <div class="error">
        You have exceeded the allowed number of presentations per month
    </div>
    <button on:click={returnHome}>return to homepage</button>
</div>

<style>
    .wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
    }
    .error {
        color: red;
        font-weight: bold;
    }
</style>
