<script lang="ts">
    import { useNavigate } from 'svelte-navigator';
    import type { Readable } from 'svelte/store';

    export let isLoggedIn: Readable<boolean>;
    export let onLoggedOut: string = '/';

    const navigate = useNavigate();

    $: if (!$isLoggedIn) {
        navigate(onLoggedOut, {
            replace: true,
        });
    }
</script>

{#if $isLoggedIn}
    <slot />
{/if}
